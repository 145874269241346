import React from "react";

import {
  Button,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import { useHistory } from 'react-router-dom';

const EMAIL_REGEX = /^\S+@\S+$/;
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function ContactForm() {
	const history = useHistory();

	const [currentField, setCurrentField] = React.useState();
	const [valid, setValid] = React.useState(false);
	const [name, setName] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [content, setContent] = React.useState('');

	React.useEffect(z => {
		setValid(true
			&& email
			&& email.length > 0
			&& EMAIL_REGEX.test(email)
			&& content
			&& content.length > 0
			);
	}, [email, content]);

	function sendMessage() {
		let message = {
			name,
			email,
			message: content
		}

		console.debug(message);

		fetch(`${API_ENDPOINT}/contact`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(message) })
			.then(r => r.json())
			.then(z => {
				setName('');
				setEmail('');
				setContent('');

				history.push('/confirm/contact');
			})
	}

	return (
		<Form id="contact-form1" method="post" role="form">
		<CardHeader className="text-center">
		  <CardTitle tag="h4">Contact Us</CardTitle>
		</CardHeader>
		<CardBody>
		  <Row>
			<Col className="pr-2" md="12">
			  <label>Name</label>
			  <InputGroup className={currentField === 'name' ? "input-group-focus" : ""}>
				<InputGroupAddon addonType="prepend">
				  <InputGroupText>
					<i className="now-ui-icons users_circle-08"></i>
				  </InputGroupText>
				</InputGroupAddon>
				<Input type="text" value={name}
					aria-label="Name..." autoComplete="name" placeholder="Name..." maxLength="100"
					onChange={e => setName(e.target.value)}
				    onFocus={z => setCurrentField('name')}
				    onBlur={z => setCurrentField()}
					/>
			  </InputGroup>
			</Col>
		  </Row>
		  <FormGroup>
			<label>Email address</label>
			<InputGroup className={currentField === 'email' ? "input-group-focus" : ""}>
			  <InputGroupAddon addonType="prepend">
				<InputGroupText>
				  <i className="now-ui-icons ui-1_email-85"></i>
				</InputGroupText>
			  </InputGroupAddon>
			  <Input type="email" value={email}
			  	autoComplete="email" placeholder="Email..." maxLength="100"
				onChange={e => setEmail(e.target.value)}
				onFocus={z => setCurrentField('email')}
				onBlur={z => setCurrentField()}
				/>
			</InputGroup>
		  </FormGroup>
		  <FormGroup>
			<label>Message</label>
			<Input type="textarea" rows="6" maxLength="1000" value={content}
				onChange={e => setContent(e.target.value)}
				/>
		  </FormGroup>
		  <Row>
			{/*
			<Col md="6">
			  <FormGroup check>
				<Label check>
				  <Input type="checkbox"></Input>
				  <span className="form-check-sign"></span>
				  I'm not a robot
				</Label>
			  </FormGroup>
			</Col>
			*/}
			<Col md="12">
			  <Button className="pull-right" color="info" disabled={!valid} onClick={sendMessage}>Send Message</Button>
			</Col>
		  </Row>
		</CardBody>
	  </Form>
	)
}
