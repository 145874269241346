import React from 'react';

import {
	Alert,
	Button,
	Col,
	Container,
	Row,
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle as warningIcon } from '@fortawesome/free-solid-svg-icons'

import OfferCard from "../TripCard";
import { Warning, Info } from 'book/steps/StepAlerts';

import moment from "moment-timezone";
import { useHistory } from 'react-router-dom';

import api from 'api';

function Searching() {
	return (
		<>
		  <Row>
			<Col className="ml-auto mr-auto">
			  <div className="info">
				<div className="icon icon-secondary">
				  <i className="now-ui-icons loader_gear spin"></i>
				</div>
				<div className="description">
				  <h2 className="info-title">Searching...</h2>
				  <p className="text-white">for available trips</p>
				</div>
			  </div>
			</Col>
		  </Row>
		</>
	);
}

function NoTripsAvailable(props) {
	return (
    <div className="pt-5">
      <Alert color="warning">
        <div className="alert-icon">
	      <FontAwesomeIcon icon={warningIcon} />
        </div>
        <strong>No available trips</strong> for this date.
        <a className="alert-link p-1" href="#change" onClick={e => { e.preventDefault(); props.stepSelect(0) }}>
          Change
        </a>
        your Pickup Date or Location to search for other trips
      </Alert>
    </div>
  );
}

export default function SelectTrip(props) {
	const history = useHistory();
	let [searching, setSearching] = React.useState(false);
	let [searchResults, setSearchResults] = React.useState();

	let { booking } = props;

	const localTime = moment().tz(booking.catalogItem.timeZone);

	React.useEffect(z => {
		let { catalogItem, travelDate, selectedReservationId } = booking;

		if (!travelDate || !catalogItem) return;

		setSearching(true);

		console.debug(`searching for trips (ts=${travelDate.format('lll')})`);

		// TODO: pass the whole booking
		let searchParams = {
			catalogItemId: catalogItem.id,
			travelTs: travelDate.toDate().getTime(),
			currentReservationId: selectedReservationId
		}
		api.searchTrips(searchParams)
			.then(setSearchResults)
			.then(z => setSearching(false))
			;
	}, [booking]);

	function earlierTrips() {
		let { catalogItem, selectedReservationId } = booking;

		let firstTrip = searchResults.events[0];

		console.debug(`searching for trips (ts=${moment(firstTrip.reservationTs).format('lll')})`);

		setSearchResults();
		setSearching(true);

		let searchParams = {
			catalogItemId: catalogItem.id,
			travelTs: firstTrip.reservationTs,
			mode: 'BEFORE',
			currentReservationId: selectedReservationId
		}
		api.searchTrips(searchParams)
			.then(setSearchResults)
			.then(z => setSearching(false))
			;
	}

	function laterTrips() {
		let { catalogItem, selectedReservationId } = booking;

		let lastTrip = searchResults.events[searchResults.events.length - 1];

		setSearchResults();
		setSearching(true);

		let searchParams = {
			catalogItemId: catalogItem.id,
			travelTs: lastTrip.reservationTs,
			mode: 'AFTER',
			currentReservationId: selectedReservationId
		}
		api.searchTrips(searchParams)
			.then(setSearchResults)
			.then(z => setSearching(false))
			;
	}

	function offerDetails(offer) {
		history.push('/offer', { offer, catalogItem: booking.catalogItem });
	}

	function commit(offer) {
		console.debug(`selected offer (${offer.scheduledDeparture})`);

		if (offer.availability === 'AVAILABLE') {
			let { travelDate, reservation } = booking;

			return api.reserve(offer, travelDate.toDate().getTime(), reservation)
				.then(rez => {
					/**
					 * NOTE: still need to store the offer (offer) at this point because i need the
					 * available seats during passenger selection; 
					 */
					props.onComplete({
						selectedReservationId: rez.id,
						trip: offer,
						reservation: rez
					});
				})
				;
		}
		else offerDetails(offer);
	}

	// all times are MST

	let trips = searchResults ? searchResults.events : [];
	let hasEarlier = searchResults && searchResults.hasEarlier;
	let hasLater = searchResults && searchResults.hasLater;

	let message = booking.catalogItem.isAirportDestination
		? <Warning>
		    Trip time refers to the <strong>first passenger</strong> pickup time.  Peak Shuttle is
		    a <strong>shared shuttle</strong> service and your pickup time may be <strong>slightly later</strong>.
		    Please note the <strong>scheduled airport arrival</strong> time when selecting your trip
	      </Warning>

		: <Info>
			Please allow <strong>20-30 minutes</strong> for baggage pickup.  Peak Shuttle monitors flight
			arrival times and will make sure you are <strong>on board</strong> before departing!
		  </Info>

	return (
		<>
		<h5 className="text-right text-uppercase">Select Trip</h5>
		<h6 className="text-right text-uppercase">BZN time: { localTime.format('lll') }</h6>
		<hr />

		{ message }

		<div className="d-flex justify-content-between">
		  <Button className="text-uppercase font-weight-bold" color="primary" disabled={!hasEarlier} onClick={earlierTrips}>
		    <i className="pr-2 now-ui-icons arrows-1_minimal-left"></i>
			  earlier
			</Button>
		  <Button className="text-uppercase font-weight-bold" color="primary" disabled={!hasLater} onClick={laterTrips}>
		    <i className="pr-2 now-ui-icons arrows-1_minimal-right"></i>
			  later
			</Button>
		</div>

		<div className="mt-3">
		  { searching && <Searching {...props} /> }
		  { !searching && trips.length === 0 && <NoTripsAvailable {...props} /> }
		  <Container>
              <Row>
				{
					trips.map(t => {
						return (
							<Col key={t.scheduledDeparture} lg="4" md="6">
							  <OfferCard trip={t} catalogItem={booking.catalogItem}
							      onTripDetails={offerDetails}
							      onTripSelected={commit}
								  />
							</Col>
						)
					})
				}
			  </Row>
		  </Container>
		</div>

		{
			/*
		<Container className="text-left">
		  { trips.length > 0 && <Timeline trip={trips[1]} /> }
		</Container>
			*/
		}
		</>
	)
}