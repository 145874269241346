import React from 'react';
import { Container } from 'reactstrap';

import Navbar from '../Navbar'
import Footer from '../Footer'

export default function ConfirmationPage(props) {
	return (
    <>
      <Navbar />
      <div className="page-header header-filter" filter-color="black">
        <div className="page-header-image" style={{ backgroundImage: "url(/img/bg7.jpg)" }}></div>
        <div className="content">
		  <Container>
		    {props.children}
		  </Container>
        </div>
        <Footer />
      </div>
    </>
  );
};