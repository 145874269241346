import {
	Button,
} from "reactstrap";

function Step(props) {
	let { title, icon, active, visited, detail } = props;

	let color = active ? 'info' : (visited ? 'success' : 'secondary');

	return (
	  <div className="mb-2 text-right d-flex justify-content-end">
		<div className="mr-3 d-flex flex-column justify-content-center">
		  <h5 className={`mb-1 ${!visited && !active ? 'text-muted' : ''}`}>{title}</h5>
		  { !active && detail && <div className="text-muted">{detail}</div> }
		</div>
        <Button className="btn-icon btn-round" color={color} disabled={!visited && !active} onClick={props.onClick}>
          <i className={`now-ui-icons ${icon}`}></i>
        </Button>
	  </div>
  );
}

export default Step;
