import React from 'react';

import {
	Alert,
	Button,
	Card,
	CardBody,
	CardFooter,
	CardTitle,
	Container,
	Row,
	Col
} from "reactstrap";

// import Page from 'views/ConfirmationPage';
import Page from 'views/FormPage';
import TripTimeline from 'book/steps/TripTimeline';
import { Info } from 'book/steps/StepAlerts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faUnlink as notFoundIcon,
	faQuestionCircle as helpIcon,
	faShuttleVan as vanIcon,
	faCreditCard as paymentIcon
} from '@fortawesome/free-solid-svg-icons'

import { Wrapper } from "@googlemaps/react-wrapper";

import { useParams, useHistory } from "react-router-dom";

import moment from 'moment-timezone';
import api from 'api.js';

const MAPS_KEY = process.env.REACT_APP_MAPS_KEY;

function NotFound() {
	const history = useHistory();

	function contactUs(e) {
		e.preventDefault();

		history.push('/bozeman', { scroll: 'contact' });
	}

	function bookNow(e) {
		e.preventDefault();

		history.push('/bozeman', { scroll: 'book' });
	}

	return (
		<>
		  <Row>
			<Col className="ml-auto mr-auto">
			  <div className="info">
				<div className="icon icon-secondary">
				  <FontAwesomeIcon icon={notFoundIcon} size="6x" />
				</div>
				<div className="description">
				  <h2 className="info-title">404!</h2>
				  <p className="text-white">Your reservation could not be found</p>
				</div>
			  </div>
			</Col>
		  </Row>
		  <Row>
			<Col className="ml-auto mr-auto">
			  <div className="pt-2">
				<Alert className="w-100" color="danger">
				  <div className="alert-icon">
					<FontAwesomeIcon icon={helpIcon} size="lg" />
				  </div>
				  Need assisstance?  Please <a className="alert-link p-1" href="#change" onClick={contactUs}>Contact Us</a>
				  We are <strong>happy</strong> to help!
				</Alert>
			  </div>
			</Col>
		  </Row>
		  <Row className="mt-5">
			<Col className="ml-auto mr-auto">
			  <Button className="btn-lg btn-round" color="primary" onClick={bookNow}>
				Book Now!
			  </Button>
			</Col>
		  </Row>
		</>
	);
}

function Loading() {
	return (
		<>
		  <Row>
			<Col className="ml-auto mr-auto">
			  <div className="info">
				<div className="icon icon-secondary">
				  <i className="now-ui-icons loader_gear spin"></i>
				</div>
				<div className="description">
				  <h2 className="info-title">Searching...</h2>
				  <p className="text-white">Looking for your reservation</p>
				</div>
			  </div>
			</Col>
		  </Row>
		</>
	);
}

const STATUS_COLORS = {
	HOLD: 'primary',
	CONFIRMED: 'primary',
	PAID: 'success'
}

function ReservationSummary(props) {
	let { rez } = props;
	let { catalogItem } = rez;

	let departureTime = moment(rez.reservationTs).tz(catalogItem.timeZone).format('LLLL');

	let passengerDecription = '0 passengers';
	if (rez.passengers && rez.passengers.length > 0) {
		passengerDecription = rez.passengers.length === 1 ? '1 passenger' : `${rez.passengers.length} passengers`;
	}

	let paymentColor = STATUS_COLORS[rez.status] || 'secondary';
	let allowPayment = rez.status === 'HOLD' || rez.status === 'CONFIRMED';

	function payNow() {
		let params = {
			reservationId: rez.id,
			tripId: rez.tripId
		}

		return api.confirmBooking(params)
			.then(res => window.location = res.paymentSession.url)
			;
	}

	let message = props.message || (
		<Info>
		  To <strong>MODIFY</strong> or <strong>CANCEL</strong> this shuttle trip, please email us 
		  at <a style={{ color: 'white' }} href="mailto://support@peak-shuttle.com">support@peak-shuttle.com</a>.
		  Within <strong>24 hours</strong> of departure, please call <a style={{ color: 'white' }} href="tel:406-209-8075">406-209-8075</a>
	    </Info>
	)

	return (
		<>
			<Container className="mt-2">
		      <Row>
				<Col lg="4" md="6">
                <Card className="card-pricing">
                  <CardBody>
                    <h6 className="category">Summary</h6>
                    <div className={"icon icon-info"}>
					  <FontAwesomeIcon icon={vanIcon} size="2x" />
                    </div>
                    <CardTitle tag="h4">{departureTime}</CardTitle>
                    <p className="card-description">
                      { catalogItem.shortDescription }
                    </p>
                  </CardBody>
                </Card>
				</Col>
				<Col lg="4" md="6">
                <Card className="card-pricing">
                  <CardBody>
                    <h6 className="category">Payment</h6>
                    <div className={`icon icon-${paymentColor}`}>
					  <FontAwesomeIcon icon={paymentIcon} size="2x" />
                    </div>
                    <CardTitle tag="h3">${rez.totalPrice}</CardTitle>
                    <p className="card-description">
                      { passengerDecription }
                    </p>
					{ allowPayment && 
                    <Button className="btn-round" color={paymentColor} onClick={payNow}>
                      Pay Now
                    </Button>
					}
                  </CardBody>
                </Card>
			    </Col>
		      </Row>
		    </Container>
			{ message }
		</>
	)
}

/**
 * TODO: consolidate with Passengers step
 * 
 * @param {*} props 
 * @returns 
 */
function PassengerCard(props) {
	let { passenger } = props;

	return (
		<Card className="card-profile step-card">
		  <div className="card-avatar">
			<img className="img img-raised" src="/img/user.png" alt="passenger" />
		  </div>
		  <CardBody>
			<CardTitle tag="h4">{ passenger.name }</CardTitle>
			<h6 className="category text-gray">{ passenger.age }</h6>
			{ passenger.price &&
			<p className="card-description">
			  ${ passenger.price }
			</p>
			}
			<CardFooter>
			</CardFooter>
		  </CardBody>
		</Card>
	)
}

function ReservationDetails(props) {
	let { reservation } = props;

	let passengers = reservation.passengers || [];

	return (
		<>
		  <Wrapper apiKey={MAPS_KEY} libraries={['places']}>
			<h5 className="detail-header text-left text-uppercase">Trip Information</h5>
			<hr />
			<ReservationSummary rez={reservation}  {...props} />

			<h5 className="mt-5 detail-header text-left text-uppercase">Trip Timeline</h5>
			<hr />
			<TripTimeline reservation={reservation} />

			<h5 className="detail-header text-left text-uppercase">Passenger Information</h5>
			<hr />
			<Container className="mt-2">
		      <Row>
			  {
				passengers.map(p => {
					return (
						<Col key={p.id} md={4} lg={3}>
						  <PassengerCard passenger={p} />
						</Col>
					)
				})
			  }
		      </Row>
		    </Container>
	      </Wrapper>
		</>
	)
}

export default function ReservationDetailsPage(props) {
	const [loading, setLoading] = React.useState(true);
	const [notFound, setNotFound] = React.useState(false);
	const [reservation, setReservation] = React.useState();

	let { tripId, id } = useParams();

	React.useEffect(z => {
		setLoading(true);
		setReservation();

		setTimeout(z => {
			api.getReservation(tripId, id)
				.then(rez => {
					setReservation(rez);
					setNotFound(false);
					setLoading(false);
				})
				.catch(e => {
					if (e.statusCode === 404) setNotFound(true);
					setLoading(false);
				})
				;
		}, 500);  // load for effect
	}, [id, tripId]);

	return (
		<>
		  <Page>
			{ loading && <Loading /> }

			{ !loading && notFound && <NotFound /> }

			{ !loading && !notFound && <ReservationDetails reservation={reservation} {...props} /> }
		  </Page>
		</>
	)
}