import React from "react";

import { Progress } from "reactstrap";

function Loading() {
  return (
    <>
      <div className="progress-container progress-primary">
        <span className="progress-badge">Loading</span>
        <Progress max="100" value="73">
          <span className="progress-value">73%</span>
        </Progress>
      </div>
    </>
  );
}

export default Loading;
