import React from "react";

import {
	Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardFooter
} from "reactstrap";

import moment from "moment-timezone";

export default function TripCard(props) {
	let { trip, catalogItem } = props;

	let [tripSelected, setTripSelected] = React.useState(false);

	let badgeColor = trip.availability === 'AVAILABLE' ? 'success' : 'danger';
	let buttonLabel = trip.availability === 'AVAILABLE' ? 'Reserve' : 'Call';
	let buttonColor = trip.availability === 'AVAILABLE' ? 'primary' : 'secondary';

	function onDetailsButton(e) {
		e.preventDefault();

		setTripSelected(true);

		props.onTripDetails(trip);
	}

	function onActionButton(e) {
		e.preventDefault();

		setTripSelected(true);

		props.onTripSelected(trip);
	}

	const { timeZone } = trip;
	const localDeparture = moment(trip.reservationTs).tz(timeZone);
	
	return (
		<Card className="card-pricing" style={{ color: 'black' }}>
		  <CardHeader>
		    <a href="#" onClick={onDetailsButton}><CardTitle tag="h3">{ localDeparture.format('LT') }</CardTitle></a>
			<div className="card-description2">
			  { trip.departureLocation } pickup
		    </div>
		  </CardHeader>
		  <CardBody className="p-2">
			<h5 className="text-muted"><Badge className="mr-1" color={badgeColor}>{trip.availableSeats} seats</Badge></h5>

			<h4 className="mt-0">${catalogItem.basePrice}/${catalogItem.additionalPassengerPrice}</h4>
		    <Button className="text-uppercase font-weight-bold" color={buttonColor} disabled={tripSelected} onClick={onActionButton}>{buttonLabel}</Button>
		  </CardBody>
		  <CardFooter>
			<div className="stats stats-right mr-2 mt-0">
			  <i className="now-ui-icons location_pin"></i>
			  { moment(trip.scheduledArrival).tz(timeZone).format('LT') }
			</div>
          </CardFooter>
	  </Card>
	)
}