import React from 'react';

import {
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
} from "reactstrap";

export default function LocationSearch(props) {
	const [hasFocus, setHasFocus] = React.useState(false);
	const inputEl = React.useRef(null);

	let { place, onPlaceChanged, searchBounds } = props;

	React.useEffect(z => {
		if (!inputEl.current || !place) return;

		inputEl.current.value = place.name;
	}, [place, inputEl]);

	React.useEffect(z => {
		// if (true) return;
		if (!inputEl.current) return;

		const options = {
			bounds: searchBounds,
			componentRestrictions: { country: "us" },
			fields: ["address_components", "geometry", "name"],
			strictBounds: true,
		};

		let ac = new window.google.maps.places.Autocomplete(inputEl.current, options);

		let listener = ac.addListener('place_changed', z => {
			let place = ac.getPlace();

			/**
			 * NOTE: If the user enters the name of a Place that was not suggested by the control
			 * and presses the Enter key, or if a Place Details request fails, the PlaceResult
			 * contains the user input in the name property, with no other properties defined
			 * 
			 * TODO: in this case, should show error message
			 */
			if (place.address_components) {
				/**
				 * NOTE: dont like how the widget puts the full place in the text field, but
				 * doesn't seem to be a way to customize this; attempted to overwrite with address,
				 * but its kind of klunky.  maybe just show address statically somewhere
				let formatted = place.address_components
					.slice(0, 3)
					.map(c => c.short_name)
					.join(', ');
				 */
	
				// setLocation(formatted);

				inputEl.current.value = place.name;

				onPlaceChanged(place);
			}
		});

		return z => listener.remove();
	}, [inputEl, onPlaceChanged, searchBounds]);

	return (
		<>
			<InputGroup className={ hasFocus ? "input-group-focus" : "" }>
			  <InputGroupAddon addonType="prepend">
				<InputGroupText>
				  <i className="now-ui-icons location_pin"></i>
				</InputGroupText>
			  </InputGroupAddon>
			  <Input type="text" innerRef={inputEl} placeholder="Location near Big Sky"
				  onFocus={z => setHasFocus(true)}
				  onBlur={z => setHasFocus(false)}
				  />
			</InputGroup>
		</>
	)
}
