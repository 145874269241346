import React from 'react';

import {
	Button,
	Row,
	Col
} from "reactstrap";

import ConfirmationPage from 'views/ConfirmationPage';

import { useLocation } from 'react-router-dom';

export default function ContactConfirmation(props) {
	let location = useLocation();

	function goHome() {
		window.location = '/';
	}

	let title = (location.state && location.state.title) || "Thanks for the message!";
	let subtitle = (location.state && location.state.subtitle) || "We'll respond via email shortly";

	return (
		<>
		<ConfirmationPage>
            <Row>
              <Col className="ml-auto mr-auto">
                <div className="info">
                  <div className="icon icon-secondary">
                    <i className="now-ui-icons arrows-1_cloud-upload-94"></i>
                  </div>
                  <div className="description">
                    <h2 className="info-title">{title}</h2>
                    <p className="text-white">{subtitle}</p>
					<Button className="btn-lg" color="primary" onClick={goHome}>Return Home</Button>
                  </div>
                </div>
              </Col>
            </Row>
		</ConfirmationPage>
		</>
	)
};