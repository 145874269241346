import React from 'react';

import { Info } from 'book/steps/StepAlerts';
import Timeline from "./TripTimeline"
import { StepNextButton } from 'Widgets';

import api from 'api';

export default function ReviewBooking(props) {
	let { booking } = props;

	const [committing, setCommitting] = React.useState(false);

// console.debug(JSON.stringify(booking, null, 2));

	function commit() {
		setCommitting(true);

		/**
		 * TODO: not sure why i'm passing passengers, location, contact here as they were saved in
		 * previous steps
		 */

		let params = {
			reservationId: booking.reservation.id,
			tripId: booking.reservation.tripId,
			passengers: booking.passengers,
			location: booking.location,
			contact: booking.contact,
		}

		return api.confirmBooking(params).then(res => {
				setCommitting(false);

				window.location = res.paymentSession.url;
			})
			;
	}

	let valid = true;

	// console.debug(booking.reservation);
	
	return (
		<>
		<h5 className="text-right text-uppercase">Review And Book</h5>
		<hr />

		  <Info>
		  <strong>Review</strong> the details of your shuttle trip.
			  <a className="alert-link p-1" href="#change" onClick={e => { e.preventDefault(); props.stepSelect(0) }}>Change</a>
				your Pickup Date or Location to search for other trips

		  </Info>

		  <div className="mt-3 d-flex flex-column justify-content-center">
		    { booking && <Timeline reservation={booking.reservation} /> }
		  </div>

		  <div className="mt-2 d-flex justify-content-end align-items-baseline">
			<StepNextButton disabled={!valid} onClick={commit} committing={committing} />
		  </div>
		</>
	)
}