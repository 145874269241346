import React from 'react';

import ReservationDetails from 'support/ReservationDetails'
import { Success } from 'book/steps/StepAlerts';

export default function BookConfirmation(props) {
	let message = (
		<Success>
		  <strong>Thank you</strong> for riding with us!  Check your email for confirmation and receipt
		</Success>
	)

	return <ReservationDetails message={message} />
};
