import React from "react";

import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
} from "reactstrap";

function ServiceCard(props) {
	let { catalogItem } = props;
	let { imageUrl, title, longDescription: description } = catalogItem;

  	return (
    <>
      <Card className="card-raised card-form-horizontal" style={{ color: "black" }}>
        <CardImg alt="..." src={imageUrl} top></CardImg>
        <CardBody className="text-left">
          <CardTitle tag="h4">{title}</CardTitle>
          <CardText>{description}</CardText>
          <Button className="mt-2" block color="primary" size="lg" onClick={z =>props.onFind(catalogItem)}>
            Book Online
          </Button>
        </CardBody>
      </Card>
    </>
  	);
}

export default ServiceCard;
