import React from "react";

import { Container } from "reactstrap";

import SectionLink from 'landing/SectionLink'

import { useHistory } from 'react-router-dom';

export function LandingNav() {
	return (
	  <nav>
		<ul>
		  <li>
			<SectionLink to='book'>Book Now</SectionLink>
		  </li>
		  {/* <li>
			<SectionLink to='myrez'>My Reservations</SectionLink>
		  </li> */}
		  <li>
			<SectionLink to='faq'>FAQ</SectionLink>
		  </li>
		  <li>
			<SectionLink to='contact'>Contact</SectionLink>
		  </li>
		  <li>
		    <a href="/terms">Terms</a>
		  </li>
		  <li>
		    <a href="/privacy">Privacy</a>
		  </li>
		  <li>
		    <a href="/cancellation">Cancellation</a>
		  </li>
		</ul>
	  </nav>
	)	
}

export function NonLandingNav() {
	const history = useHistory();
	
	function navLanding(e, to) {
		e.preventDefault();

		history.push('/bozeman', { scroll: to });
	}
	
	return (
		<nav>
		  <ul>
		    <li>
			  <a href="#book" onClick={e => navLanding(e, 'book')}>Book Now</a>
		    </li>
		  {/* <li>
			<SectionLink to='myrez'>My Reservations</SectionLink>
		  </li> */}
		    <li>
			  <a href="#faq" onClick={e => navLanding(e, 'faq')}>FAQ</a>
		    </li>
		    <li>
			  <a href="#contact" onClick={e => navLanding(e, 'contact')}>Contact</a>
		    </li>
			<li>
		      <a href="/terms">Terms</a>
		    </li>
		    <li>
		      <a href="/privacy">Privacy</a>
		    </li>
		    <li>
		      <a href="/cancellation">Cancellation</a>
		    </li>
		  </ul>
	    </nav>
	)	
}

function Footer(props) {
	return (
    	<footer className="footer">
          <Container>
		    { props.isLanding ? <LandingNav /> : <NonLandingNav /> }

            <div className="copyright" id="copyright">
              © {new Date().getFullYear()} Peak Shuttle, LLC
            </div>
          </Container>
        </footer>
  );
}

export default Footer;
