import React from 'react';

import {
	Alert,
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faInfoCircle as infoIcon,
	faQuestionCircle as questionIcon,
	faExclamationTriangle as warningIcon,
	faThumbsUp as successIcon,
} from '@fortawesome/free-solid-svg-icons'

function Info(props) {
	return (
		<>
		<Alert color="info" className="p-3">
		  <div className="alert-icon">
			<FontAwesomeIcon icon={infoIcon} size="2x" />
		  </div>
		  { props.children }
		</Alert>
		</>
	)
}

function Question(props) {
	return (
		<>
		<Alert color="success" className="p-3">
		  <div className="alert-icon">
			<FontAwesomeIcon icon={questionIcon} size="2x" />
		  </div>
		  { props.children }
		</Alert>
		</>
	)
}

function Warning(props) {
	return (
		<>
		<Alert color="warning" className="p-3">
		  <div className="alert-icon">
			<FontAwesomeIcon icon={warningIcon} size="2x" />
		  </div>
		  { props.children }
		</Alert>
		</>
	)
}

function Success(props) {
	return (
		<>
		<Alert color="success" className="p-3">
		  <div className="alert-icon">
			<FontAwesomeIcon icon={successIcon} size="2x" />
		  </div>
		  { props.children }
		</Alert>
		</>
	)
}

export {
	Info,
	Question,
	Warning,
	Success
}