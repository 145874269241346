import React from "react";

import {
  Card,
  Container,
  Row,
  Col,
} from "reactstrap";

import { Element } from 'react-scroll'
import SectionLink from './SectionLink';
import ContactForm from 'support/ContactForm';

function ContactUs() {
  return (
    <>
      <div className="cd-section" id="contact-us">
		<Element name="contact" />
        <div className="contactus-1 section-image">
          <Container>
            <Row>
              <Col md="5">
                <h2 className="title">Questions?</h2>
                <h4 className="description">
                  If our <SectionLink to="faq">FAQ</SectionLink> didn't anwswer any questions you may have, please send us a message
                </h4>
				{/*
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons location_pin"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Find us at the office</h4>
                    <p className="description">
                      Bld Mihail Kogalniceanu, nr. 8, <br></br>
                      7652 Bucharest, <br></br>
                      Romania
                    </p>
                  </div>
                </div>
				*/}
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons tech_mobile"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Give us a call</h4>
                    <p className="description">
					<a href="tel:406-209-8075" target="_blank" rel="noreferrer">406-209-8075</a>
					</p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons ui-1_email-85"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Send us an email</h4>
                    <p className="description">
					  <a href="mailto:support@peak-shuttle.com" target="_blank" rel="noreferrer">support@peak-shuttle.com</a>
					</p>
                  </div>
                </div>
              </Col>
              <Col className="ml-auto mr-auto" md="5">
                <Card className="card-contact card-raised">
				  <ContactForm />
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
