import React from "react";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faUserPlus as addIcon,
} from '@fortawesome/free-solid-svg-icons'

import { Info, Warning } from 'book/steps/StepAlerts';
import { StepNextButton } from 'Widgets';

import api from 'api';

let PASSENGER_ID = 1;

const STEP_STYLES = {
	card: {
		textAlign: "center",
		backgroundColor: "white",
		color: "black"
	}
}

let PASSENGER_AGES = [
	"Adult",
	"Child (0-4)",
	"Child (5-8)",
	"Child (8-12)",
]

const NAME_REGEX = /^[\w\-\s]*$/;

function PassengerAgeSelector(props) {
	let { selectedItem } = props;

	return (
		<UncontrolledDropdown className="btn-group">
		  <DropdownToggle
				aria-expanded={false}
				aria-haspopup={true}
				caret
				color="info"
				data-toggle="dropdown"
				type="button"
				className="text-uppercase font-weight-bold"
		  		>
			{ selectedItem }
		  </DropdownToggle>
		  <DropdownMenu>
			{
				PASSENGER_AGES.map(item =>
					<DropdownItem key={item} onClick={z => props.onItemSelected(item)}>
					  { item }
					</DropdownItem>
				)
			}
		  </DropdownMenu>
		</UncontrolledDropdown>
	);
}
  
function PassengerCard(props) {
	let { passenger, handleRemove } = props;

	function onRemoveClick(e) {
		e.preventDefault();

		handleRemove(passenger);
	}

	return (
		<Card className="card-profile" style={STEP_STYLES.card}>
		  <div className="card-avatar">
			<img className="img img-raised" src="/img/user.png" alt="passenger" />
		  </div>
		  <CardBody>
			<CardTitle tag="h4">{ passenger.name }</CardTitle>
			<h6 className="category text-gray">{ passenger.age }</h6>
			<p className="card-description">
			  ${ passenger.price }
			</p>
			<CardFooter>
			  <Button className="btn-round" color="danger" onClick={onRemoveClick}>
                Remove
              </Button>
			</CardFooter>
		  </CardBody>
		</Card>
	)
}

function AddPassengerForm(props) {
	const [currentField, setCurrentField] = React.useState();
	const [valid, setValid] = React.useState(true);
	const [name, setName] = React.useState('');
	const [age, setAge] = React.useState(PASSENGER_AGES[0]);

	let { disabled, addPassenger } = props;

	function nameChanged(e) {
		let newName = e.target.value;

		setName(newName);
		setValid(NAME_REGEX.test(newName));
	}

	function onAddClicked(e) {
		e.preventDefault();

		addPassenger(name, age);
		setName('');
		setAge(PASSENGER_AGES[0]);
		setValid(true);
	}

	return (
		<>
		  <Card className="mt-3 card-raised card-form-horizontal card-pricing" style={STEP_STYLES.card}>
            <CardBody>
		      <div className="d-flex">
		        <InputGroup className={currentField === "name" ? "input-group-focus mr-3" : "mr-3"}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_single-02"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" value={name} autoComplete="name"
                	placeholder="Passenger name (optional)"
                	maxLength="100"
                	onChange={nameChanged}
                	onFocus={z => setCurrentField("name")}
                	onBlur={z => setCurrentField()}
              	    />
                </InputGroup>
		        <PassengerAgeSelector className="ml-3" selectedItem={age} onItemSelected={setAge} />
		      </div>
			  <div className="mt-3 d-flex justify-content-center">
				<Button className="text-uppercase" color="primary"
					disabled={disabled || !valid}
					onClick={onAddClicked}>
				  <FontAwesomeIcon className="mr-2" icon={addIcon} />
				  Add
				</Button>
			  </div>
		    </CardBody>
		  </Card>
		</>
	)
}

export default function Passengers(props) {
	const [passengers, setPassengers] = React.useState([]);
	const [valid, setValid] = React.useState(false);
	const [committing, setCommitting] = React.useState(false);

	let { booking } = props;
	let { catalogItem, trip } = booking;

	// <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>

	// console.debug('******', catalogItem.maxPassengers);

	function addPassenger(name, age) {
		let id = PASSENGER_ID++;
		let newName = name ? name.trim() : `Passenger`;

		let newPassenger = {
			id,
			name: newName,
			age,
			primary: passengers.length === 0,
			price: passengers.length === 0 ? catalogItem.basePrice : catalogItem.additionalPassengerPrice
		}

		let newPassengers = [...passengers, newPassenger];

		setPassengers(newPassengers);
		setValid(newPassengers.length > 0 && newPassengers.length <= trip.availableSeats);
	}

	React.useEffect(z => {
		if (booking.passengers && booking.passengers.length > 0) {
			setPassengers(booking.passengers);
			setValid(booking.passengers.length > 0 && booking.passengers.length <= trip.availableSeats);
		}
	}, [booking, trip.availableSeats]);

	function removePassenger(passenger) {
		let newPassengers = [].concat(passengers);
		let index = newPassengers.findIndex(p => p.id === passenger.id);

		if (index >= 0) {
			let p = newPassengers[index];

			newPassengers.splice(index, 1);

			if (p.primary && newPassengers.length > 0) {
				newPassengers[0].primary = true;
				newPassengers[0].price = catalogItem.basePrice;
			}

			setPassengers(newPassengers);
			setValid(newPassengers.length > 0 && newPassengers.length <= trip.availableSeats);
		}
	}

	function commit() {
		console.debug(`committed passenger information`);

		setCommitting(true);

		let params = {
			reservationId: booking.reservation.id,
			tripId: booking.reservation.tripId,
			passengers
		}

		return api.updateBooking(params)
			.then(reservation => {
				setCommitting(false);

				/**
				 * NOTE: still need to store the trip (offer) at this point because i need the
				 * available seats during passenger selection; 
				 */
				props.onComplete({
					passengers,
					reservation
				});
			})
			;
	}

	const totalPassengersText = `Book shuttle for ${passengers.length} passenger${passengers.length > 1 ? 's' : ''}`;

	return (
		<>
		<h5 className="text-right text-uppercase">Passenger Information</h5>
		<hr />

		<Info>There are <strong>{trip.availableSeats} seats</strong> available on this trip.  If
		you are traveling with more than {trip.availableSeats} passengers
		try <a className="alert-link p-1" href="#change" onClick={e => { e.preventDefault(); props.stepSelect(2) }}>Selecting a Trip</a> with
		more available seats</Info>

		<Warning>
		  <strong>Montana law</strong> requires child safety restraints for passengers 6 years of age and under, or weighing less than 60 pounds.  We provide a variety of child safety seats including rear-facing, forward-facing, and booster seats for children under the age of 12
		</Warning>


		<Container className="mt-3">
		  <Row>
			<Col lg={12}>
		      <AddPassengerForm disabled={passengers.length >= trip.availableSeats} addPassenger={addPassenger} />
			</Col>
		  </Row>
		  <Row>
			{
				passengers.map(p => {
					return (
						<Col key={p.id} md={6} lg={4}>
						  <PassengerCard passenger={p} handleRemove={removePassenger} />
						</Col>
					)
				})
			}
		  </Row>
		</Container>

	    <div className="mt-5 d-flex justify-content-end align-items-baseline">
		  <h6 className="text-uppercase mr-3">{totalPassengersText}</h6>
		  <StepNextButton disabled={!valid} onClick={commit} committing={committing} />
		</div>
		</>
	)
}