import React from 'react';

import {
	Button,
	Container
}
from 'reactstrap';

export default function SupportPage(props) {
	function goHome() {
		window.location = '/';
	}

	return (
		<>
		  <div className="page-header header-filter" filter-color="black">
		    <div className="page-header-image" style={{ backgroundImage: "url(/img/bg7.jpg)" }}></div>
			<div className="content">
			  <Container>
			    <h1>Oops!</h1>
			    <h3>Something went wrong!</h3>

				<Button className="text-uppercase" color="primary" onClick={goHome}>Go Home</Button>
			  </Container>
			</div>
		  </div>
		</>
	)
}
