import moment from "moment-timezone";

/**
 * Custom hook to provide common moments based on the local time in a time zone
 * 
 * WIP
 * 	- this would maybe be more efficient as a Context
 * 
 * @param {*} ts 
 * @param {*} timeZone 
 * @returns 
 */
function useLocalTime(ts, timeZone) {
	let localTime;

	if (moment.isMoment(ts)) localTime = ts;
	else if (timeZone) localTime = moment.tz(ts, timeZone);
	else localTime = moment(ts);

	let startOfDay = moment(localTime).startOf('day');
	let endOfDay = moment(localTime).endOf('day');

	return [localTime, startOfDay, endOfDay];
}

export default useLocalTime;
