import React from 'react';

import {
	Button,
	Card,
	CardBody,
	CardTitle,
	Container,
	Row,
	Col
} from "reactstrap";

// import Page from 'views/ConfirmationPage';
import Page from 'views/FormPage';
import TripTimeline from 'book/steps/TripTimeline';
import { Info, Warning } from 'book/steps/StepAlerts';
import TripMessageForm from 'support/TripMessageForm';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faShuttleVan as vanIcon,
	faCreditCard as paymentIcon,
	faStepBackward as backIcon
} from '@fortawesome/free-solid-svg-icons'

import { Wrapper } from "@googlemaps/react-wrapper";

import { useHistory, useLocation } from 'react-router-dom';

import moment from 'moment-timezone';

const MAPS_KEY = process.env.REACT_APP_MAPS_KEY;

function OfferSummary(props) {
	let history = useHistory();

	let { offer, catalogItem } = props;

	let departureTime = moment(offer.reservationTs).tz(catalogItem.timeZone).format('LLLL');

	let allowReservation = offer.availability === 'AVAILABLE';

	function reserve() {
		/*
		let params = {
			reservationId: offer.id,
			tripId: offer.tripId
		}

		return api.confirmBooking(params)
			.then(res => window.location = res.paymentSession.url)
			;
		*/
	}

	function goBack() {
		return history.goBack();
	}

	let message;
	if (offer.availability === 'INQUIRE') {
		message = 
		<Warning>
		  This shuttle will be <strong>departing soon</strong>.  To inquire
		  about <strong>availability</strong> and <strong>book</strong> a reservation for this trip, please
		  call <a style={{ color: 'white' }} href="tel:406-209-8075">406-209-8075</a>
	    </Warning>
	}
	else if (offer.availability === 'DEPARTED') {
		message = 
		<Warning>
		  This shuttle has <strong>departed</strong>.  To inquire
		  about the next <strong>available</strong> shuttle, please
		  call <a style={{ color: 'white' }} href="tel:406-209-8075">406-209-8075</a>
	    </Warning>
	}
	else if (offer.availability === 'FULL') {
		message = 
		<Info>
		  This shuttle is <strong>currently full</strong>.  To get on the waiting list for this
		  departure, please send us a message below with a phone number and the number of passengers
		  in your group.  We will <strong>contact you</strong> in the event seats become available
	    </Info>
	}

	let allowMessage = offer.availability === 'FULL';

	return (
		<>
			{ message }
			<Container className="mt-2">
		      <Row>
				<Col lg="4" md="6">
                <Card className="card-pricing">
                  <CardBody>
                    <h6 className="category">Summary</h6>
                    <div className="icon icon-info">
					  <FontAwesomeIcon icon={vanIcon} size="2x" />
                    </div>
                    <CardTitle tag="h4">{departureTime}</CardTitle>
                    <p className="card-description">
                      { catalogItem.shortDescription }
                    </p>
                  </CardBody>
                </Card>
				</Col>
				<Col lg="4" md="6">
                <Card className="card-pricing">
                  <CardBody>
                    <h6 className="category">Price</h6>
                    <div className={`icon icon-success`}>
					  <FontAwesomeIcon icon={paymentIcon} size="2x" />
                    </div>
					<CardTitle tag="h3">${catalogItem.basePrice} <small>first passenger</small></CardTitle>
					<h4>${catalogItem.additionalPassengerPrice} <small>each additional passenger</small></h4>
					{ allowReservation && false &&
                    <Button className="btn-round" color="primary" onClick={reserve}>
                      Reserve
                    </Button>
					}
                  </CardBody>
                </Card>
			    </Col>
				<Col lg="4" md="6">
                <Card className="card-pricing">
                  <CardBody>
                    <h6 className="category">More Trips</h6>
                    <div className={`icon icon-primary`}>
					  <FontAwesomeIcon icon={backIcon} size="2x" />
                    </div>
					<h4>Return to search results to find other available trips</h4>
                    <Button className="btn-round" color="primary" onClick={goBack}>
                      Go Back
                    </Button>
                  </CardBody>
                </Card>
			    </Col>
		      </Row>
			  { allowMessage && <Row>
				<Col md="12">
                <Card className="card-pricing">
                  <CardBody>
				    <TripMessageForm trip={offer} />
                  </CardBody>
                </Card>
			    </Col>
		      </Row> }
		    </Container>
		</>
	)
}

function OfferDetails(props) {
	let { offer } = props;

	return (
		<>
		  <Wrapper apiKey={MAPS_KEY} libraries={['places']}>
			<h5 className="detail-header text-left text-uppercase">Trip Information</h5>
			<hr />
			<OfferSummary {...props} />

			<h5 className="mt-5 detail-header text-left text-uppercase">Trip Timeline</h5>
			<hr />
			<TripTimeline reservation={offer} />
	      </Wrapper>
		</>
	)
}

export default function OfferDetailsPage(props) {
	let location = useLocation();
	let { offer, catalogItem } = location.state;

	return (
		<>
		  <Page>
		    <OfferDetails offer={offer} catalogItem={catalogItem} {...props} />
		  </Page>
		</>
	)
}