import React from 'react';

import {
	Card,
	CardBody,
	CardFooter,
	Container,
	Row,
	Col
} from "reactstrap";

import Step from './Step';

import TravelDate from './steps/TravelDate';
import Flight from './steps/Flight';
import SelectTrip from './steps/SelectTrip';
import Location from './steps/Location';
import ContactInformation from './steps/ContactInformation';
import Passengers from './steps/Passengers';
import ReviewAndBook from './steps/ReviewAndBook';
import FormPage from 'views/FormPage';

import { useLocation } from 'react-router-dom';

import { Wrapper } from "@googlemaps/react-wrapper";

import moment from "moment-timezone";

function deriveSteps(booking) {
	const pickupStep = {
		id: '10',
		title: 'Pickup Date/Location',
		icon: 'ui-1_calendar-60',
		Component: TravelDate
	}
	const flightStep = {
		id: '50',
		title: 'Flight Info',
		icon: 'users_circle-08',
		Component: props => <Flight {...props} />
	}
	const passengersStep = {
		id: '40',
		title: 'Passengers',
		icon: 'users_circle-08',
		Component: props => <Passengers {...props} />
	}
	const tripStep = {
		id: '20',
		title: 'Select Trip',
		icon: 'transportation_bus-front-12',
		Component: SelectTrip
	}
	const locationStep = {
		id: '30',
		// TODO: this should probably use something on the booking, not the catalog item
		title: booking.catalogItem.arrivalLocation === 'BZN' ? 'Pickup Location' : 'Dropoff Location',
		icon: 'location_pin',
		Component: props => <Location {...props} />
	}
	const contactStep = {
		id: '60',
		title: 'Contact Info',
		icon: 'ui-1_email-85',
		Component: props => <ContactInformation {...props} />
	}
	const bookStep = {
		id: '100',
		title: 'Review and Book',
		icon: 'shopping_credit-card',
		Component: ReviewAndBook
	}

	let steps = [
		pickupStep,
		flightStep,
		tripStep,
		passengersStep,
		locationStep,
		contactStep,
		bookStep
	]
	
	if (booking) {
		const timeZone = booking.catalogItem.timeZone;
		
		if (booking.travelDate) {
			pickupStep.visited = true;
			pickupStep.detail = `${booking.travelDate.format("MMM D, YYYY")} / ${booking.catalogItem.departureLocation}`;
		}
		if (booking.travelDate && booking.flight) {
			flightStep.visited = true;
			// flightStep.detail = `${booking.flight.airline.name} - ${booking.travelDate.format("LT")}`;
			flightStep.detail = `${booking.travelDate.format("LT")}`;
		}
		if (booking.reservation) {
			tripStep.visited = true;
			tripStep.detail = moment(booking.reservation.reservationTs).tz(timeZone).format("LT");
		}
		if (booking.location) {
			locationStep.visited = true;
			locationStep.detail = booking.location.name;
		}
		if (booking.contact) {
			const { email } = booking.contact;

			contactStep.visited = true;
			contactStep.detail = email;
		}
		if (booking.passengers && booking.passengers.length > 0) {
			const { passengers } = booking;

			passengersStep.visited = true;
			passengersStep.detail = `${passengers.length} ${passengers.length === 1 ? 'passenger' : 'passengers'}`;
		}
	}

	return steps;
}

function deriveActiveIndex(booking) {
	if (!booking.travelDate || !booking.catalogItem) return 0;
	if (!booking.flight) return 1;
	if (!booking.reservation) return 2;
	if (!booking.passengers || booking.passengers.length < 1) return 3;
	if (!booking.location) return 4;
	if (!booking.contact) return 5;

	return 6;
}

function CardNav(props) {
	let { booking, steps, activeIndex, onStepSelected } = props;

	let tripCost = booking.reservation ? booking.reservation.totalPrice : 0;

	return (
    <>
      <Card data-background-color="black" className="text-right">
        <CardBody>
          <h6 className="category-social">Book Shuttle</h6>
          <p className="card-description mb-5">
            Complete steps to reserve
          </p>
		  {
			  steps.map((s, i) => {
				  return (
					<Step key={s.id}
						title={s.title}
						icon={s.icon}
						active={activeIndex === i}
						visited={s.visited}
						detail={s.detail}
						onClick={z => onStepSelected(s)}
						/>
				  )
			  })
		  }
          <CardFooter>
            <h5 className="stats stats-right">
              <i className="mr-2 now-ui-icons shopping_cart-simple"></i>${tripCost || 0}
            </h5>
          </CardFooter>
        </CardBody>
      </Card>
    </>
  	);
}

function BookWizard(props) {
	let location = useLocation();
	let [booking, setBooking] = React.useState({
		catalogItem: (location.state && location.state.catalogItem) || undefined,
		travelDate: undefined,  // this is really the flight arrival/departure date/time
		flight: undefined,
		trip: undefined, // this is really the offer
		location: undefined,
		contact: undefined,
		passengers: [],
	});
	/*
	let [booking, setBooking] = React.useState({
		catalogItem: (location.state && location.state.catalogItem) || undefined,
		travelDate: moment().add(1, 'days'),
		flight: {
			airline: {
				id: '1',
				name: 'Delta',
				image: undefined
			},
			time: moment().add(1, 'days').set('hour', 12).toDate().getTime()
		},
		trip: undefined, // this is the selected trip
		location: {
			address_components: [{
				short_name: '145'
			}, {
				short_name: 'Town Center Ave'
			}, {
				short_name: 'Big Sky'
			}],
			geometry: {
				location: { lat: 45.26031589999999, lng: -111.3099563 }
			},
			name: 'Residence Inn by Marriott Big Sky/The Wilson Hotel'
		},
		contact: { email: 'djghokie@gmail.com' },
		passengers: ['test passenger'],
	});
	*/

	function setActiveStepIndex(previousIndex, action) {
		// console.debug('$$$$$$$$', previousIndex, action);

		if (action.type === 'DERIVE') return deriveActiveIndex(booking);
		
		// user navigation
		return action.activeIndex;
	}

	let [activeIndex, activeIndexDispatch] = React.useReducer(setActiveStepIndex, 0, z => deriveActiveIndex(booking));

	let steps = deriveSteps(booking);
	let activeStep = steps[activeIndex];

	// console.debug('^^^^^^^^', activeStep ? activeStep.id : 'none');

	function stepSelect(step) {
		if (step.id !== activeStep.id) {
			let newIndex = steps.findIndex(s => s.id === step.id);
			// if (newIndex >= 0) setActiveIndex(newIndex);

			activeIndexDispatch({ activeIndex: newIndex });
		}
	}

	function stepComplete(committedData) {
		// NOTE: this is considered a functional update (derived from previous booking)
		setBooking(oldBooking => {
			let newBooking = Object.assign({}, oldBooking, committedData);
	
			// console.debug('#######', activeStep.id, newBooking);

			return newBooking;
		});

		/**
		 * NOTE: only issue with this approach is that the previous step gets re-rendered before
		 * the "activeIndex" changes; this causes SelectTrip to call the search api again after a
		 * trip selection has been made; this may or may not cause a problem later; it does however
		 * cause an error in the console related to updated state in SelectTrip after the component
		 * has been unmounted;
		 * 
		 * fix might be to combine booking and activeIndex state (wizard state)
		 */

		activeIndexDispatch({ type: 'DERIVE' });
	}

	let catalogItems = location && location.state ? location.state.catalogItems : [];

	return (
		<Container>
		  <Row>
		    <Col className="ml-auto mr-auto" md="4">
		      <CardNav booking={booking} steps={steps} activeIndex={activeIndex} onStepSelected={stepSelect} />
		    </Col>
		    <Col className="ml-auto mr-auto pt-3" md="8" style={{ color: 'white' }}>
			  {/* probably show loading widget if no active step */}

			  {
			  	activeStep && <activeStep.Component
			  		booking={booking}
					catalogItems={catalogItems}
					updateBooking={setBooking}
					// stepSelect={setActiveIndex}
					stepSelect={index => activeIndexDispatch({ activeIndex: index })}
					onComplete={stepComplete}
					/>
			  }
		    </Col>
		  </Row>
	  </Container>
	)
}

export default function Book(props) {
	return (
		<>
		  <FormPage>
		    <Wrapper apiKey={process.env.REACT_APP_MAPS_KEY} libraries={['places']}>
		      <BookWizard {...props} />
	        </Wrapper>
		  </FormPage>
		</>
	)
}