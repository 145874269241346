import {
	Nav,
	NavItem,
	NavLink,
} from "reactstrap";
  
import SectionNav from 'landing/SectionNav';

import { useHistory } from 'react-router-dom';

export function LandingNav() {
	return (
		<Nav className="mx-auto" navbar>
		  <SectionNav to="book">Book Now</SectionNav>
		  <SectionNav to="faq">FAQ</SectionNav>
		  <SectionNav to="contact">Contact</SectionNav>
		</Nav>
	)	
}

export function NonLandingNav() {
	const history = useHistory();

	function navLanding(to) {
		history.push('/bozeman', { scroll: to });
	}

	return (
		<Nav className="mx-auto" navbar>
		  <NavItem>
			<NavLink href="#" onClick={z => navLanding('book')}>Book Now</NavLink>
		  </NavItem>
		  {/* <SectionNav to="myrez">My Reservations</SectionNav> */}
		  <NavItem>
			<NavLink href="#" onClick={z => navLanding('faq')}>FAQ</NavLink>
		  </NavItem>
		  <NavItem>
			<NavLink href="#" onClick={z => navLanding('contact')}>Contact</NavLink>
		  </NavItem>
	    </Nav>
	)	
}