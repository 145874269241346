import React from 'react';

import {
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
} from "reactstrap";
  
import ReactDatetime from "react-datetime";
import { Info } from 'book/steps/StepAlerts'
import { StepNextButton } from 'Widgets';
import moment from "moment-timezone";
import useLocalTime from "hook/useLocalTime";

function LocationSelector(props) {
	let { items, labelProperty, selectedItem, color } = props;

	return (
		<UncontrolledDropdown className="btn-group">
		  <DropdownToggle
				aria-expanded={false}
				aria-haspopup={true}
				caret
				color={color}
				data-toggle="dropdown"
				type="button"
				className="text-uppercase font-weight-bold"
		  		>
			{selectedItem[labelProperty]}
		  </DropdownToggle>
		  <DropdownMenu>
			{
				items.map(item =>
					<DropdownItem key={item.id} onClick={z => props.onItemSelected(item)}>
					  {item[labelProperty]}
					</DropdownItem>
				)
			}
		  </DropdownMenu>
		</UncontrolledDropdown>
	);
}
  
export default function TravelDate(props) {
	let { booking, catalogItems } = props;

	const [valid, setValid] = React.useState(false);
	const [travelDate, setTravelDate] = React.useState(moment());
	const [catalogItem, setCatalogItem] = React.useState(catalogItems[0]);
	
	const { timeZone } = catalogItem;

	let [localTime] = useLocalTime(Date.now(), timeZone);

	const yesterday = localTime.clone().subtract(1, "day");

	React.useEffect(z => {
		let ci = booking.catalogItem || catalogItems[0];

		if (booking.travelDate) setTravelDate(booking.travelDate);
		else {
			let hoursInFuture = ci.isAirportDestination ? 4 : 1;
			setTravelDate(moment(localTime).add(hoursInFuture, 'hours').startOf('hour'));
		}

		setCatalogItem(ci);
  	}, [booking]);

	React.useEffect(z => {
		const effectYesterday = moment().tz(timeZone).subtract(1, "day");

		setValid(travelDate && travelDate.isAfter(effectYesterday));
  	}, [travelDate, timeZone]);

	function handleTravelDateChanged(newTravelDate) {
		if (localTime.isSame(newTravelDate, 'day')) {
			let hoursInFuture = catalogItem.isAirportDestination ? 4 : 1;
			setTravelDate(moment(localTime).add(hoursInFuture, 'hours').startOf('hour'));
		}
		else setTravelDate(moment(newTravelDate).set('hour', 14).startOf('hour'));
	}

	function commit() {
		console.debug(`committed travel date (${travelDate.format('l')})`);

		let updatedBooking = {}

		if (!booking.travelDate || !travelDate.isSame(booking.travelDate)) {
			// console.debug('#######', travelDate.format('L LT'));

			updatedBooking.travelDate = travelDate;
			updatedBooking.flight = undefined;  // clear out previous flight
			updatedBooking.trip = undefined;  // clear out previous selected offer
			updatedBooking.reservation = undefined;  // clear out previous reservation
		}

		if (!booking.catalogItem || catalogItem.id !== booking.catalogItem.id) {
			updatedBooking.catalogItem = catalogItem;
			updatedBooking.flight = undefined;  // clear out previous flight
			updatedBooking.trip = undefined;  // clear out previous selected offer
			updatedBooking.location = undefined;  // clear out previous location
			updatedBooking.reservation = undefined;  // clear out previous reservation
		}

		props.onComplete(updatedBooking);
	}

	console.debug(`rendering TravelDate (${ travelDate.format('L LT') })`);

	return (
		<>
		<h5 className="text-right text-uppercase">Pickup Date/Location</h5>
		<h6 className="text-right text-uppercase">BZN time: { localTime.format('lll') }</h6>
		<hr />

		<div className="pt-2">
		  <Info>
			We service addresses, including hotels, lodges, ranches, and private residences, within a 10 mile radius
			of the Big Sky, MT area.  After selecting a shuttle trip, you will provide the address of your
			pickup/dropoff location
		  </Info>
		</div>

		<div className="mt-3">
		  <div className="d-flex justify-content-center align-items-center">
			<h6 className="mr-3">pickup</h6>
		    <LocationSelector items={catalogItems} labelProperty="pickupLabel" selectedItem={catalogItem} color="success"
				onItemSelected={setCatalogItem}
				/>
		  </div>
		</div>

		<div className="mt-5">
		  <div className="d-flex justify-content-center align-items-start">
		    <h6 className="text-uppercase mr-3">select date of travel</h6>
			<div style={{ color: "black" }}>
		      <ReactDatetime
			  	  input={false}
                  timeFormat={false}
                  value={travelDate}
                  isValidDate={current => current.isAfter(yesterday)}
                  onChange={handleTravelDateChanged}
                />
			</div>
		  </div>
		</div>
		<div className="d-flex justify-content-end">
		  <StepNextButton disabled={!valid} onClick={commit} />
		</div>
		</>
	)
}