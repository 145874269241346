import { ScrollLink } from 'react-scroll';

const ScrollableAnchor = ScrollLink(function(props) {
	return (
		<a {...props} href="#scroll">
		  { props.children }
        </a>
	)
})

function SectionLink(props) {
	return (
		<ScrollableAnchor {...props} smooth={true} spy={true} duration={500}>
		  { props.children }
		</ScrollableAnchor>
	)
}


export default SectionLink;