import React from 'react';

import 'App.css';

import Navbar from './Navbar'
import Footer from './Footer'
import BookNow from 'landing/BookNow';
import FAQ from 'landing/FAQ';
import Contact from 'landing/Contact';

import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll'

function Landing() {
	const location = useLocation();

	React.useEffect(z => {
		if (location.state && location.state.scroll)
			scroller.scrollTo(location.state.scroll, { duration: 500, delay: 500, smooth: true })
	})

	return (
        <div className="header-2">
		  <Navbar isLanding={true} />
		  <div className="features-2 section-image" style={{ backgroundImage: "url(img/bigsky-3.jpeg)" }}>
			<BookNow />
          </div>
		  <FAQ />
		  <Contact />
		  <Footer isLanding={true} />
       </div>
	)
}

export default Landing;
