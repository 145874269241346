import React from "react";

import {
  Button,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import { useHistory } from 'react-router-dom';
import api from 'api';

const EMAIL_REGEX = /^\S+@\S+$/;

const CONTACT_REASONS = [{
	key: 'availability',
	label: 'Email me if this trip becomes available'
}, {
	key: 'question',
	label: 'I have a question about this trip'
}]

function ReasonSelector(props) {
	let selectedReason = props.reason;

	return (
		<UncontrolledDropdown className="btn-group">
		  <DropdownToggle
				aria-expanded={false}
				aria-haspopup={true}
				caret
				color="secondary"
				data-toggle="dropdown"
				type="button"
				className="text-uppercase font-weight-bold"
		  		>
			{ selectedReason.label }
		  </DropdownToggle>
		  <DropdownMenu>
			{
				CONTACT_REASONS.map(item =>
					<DropdownItem key={item.key} onClick={z => props.onSelected(item)}>
					  {item.label}
					</DropdownItem>
				)
			}
		  </DropdownMenu>
		</UncontrolledDropdown>
	);
}

export default function TripMessageForm(props) {
	const history = useHistory();

	const [currentField, setCurrentField] = React.useState();
	const [valid, setValid] = React.useState(false);
	const [reason, setReason] = React.useState(CONTACT_REASONS[0]);
	const [name, setName] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [content, setContent] = React.useState('');

	React.useEffect(z => {
		setValid(true
			&& email
			&& email.length > 0
			&& EMAIL_REGEX.test(email)
			);
	}, [email]);

	function sendMessage() {
		let message = {
			reason: reason.key,
			name,
			email,
			message: content,
			trip: props.trip
		}

		console.debug(message);

		return api.sendMessage(message)
			.then(r => {
				setCurrentField();
				setReason(CONTACT_REASONS[0]);
				setName('');
				setEmail('');
				setContent('');
	
				history.push('/confirm/contact', { title: 'Thank you!', subtitle: 'We will email you if this trip becomes available' });
			})
			;
	}

	return (
		<>
		<Form className="p-3 text-left">
		  <Row>
			<Col>
			  <ReasonSelector reason={reason} onSelected={setReason} />
			</Col>
		  </Row>
		  <Row className="m-2">
			<Col lg="4">
			  <InputGroup className={currentField === 'name' ? "input-group-focus" : ""}>
				<InputGroupAddon addonType="prepend">
				  <InputGroupText>
					<i className="now-ui-icons users_circle-08"></i>
				  </InputGroupText>
				</InputGroupAddon>
				<Input type="text" value={name}
					aria-label="Name..." autoComplete="name" placeholder="Name..." size="100" maxLength="100"
					onChange={e => setName(e.target.value)}
				    onFocus={z => setCurrentField('name')}
				    onBlur={z => setCurrentField()}
					/>
			  </InputGroup>
			</Col>
		  </Row>
		  <Row className="m-2">
		  <Col lg="4">
		  <FormGroup>
			<InputGroup className={currentField === 'email' ? "input-group-focus" : ""}>
			  <InputGroupAddon addonType="prepend">
				<InputGroupText>
				  <i className="now-ui-icons ui-1_email-85"></i>
				</InputGroupText>
			  </InputGroupAddon>
			  <Input type="email" value={email}
			  	autoComplete="email" placeholder="Email..." maxLength="100"
				onChange={e => setEmail(e.target.value)}
				onFocus={z => setCurrentField('email')}
				onBlur={z => setCurrentField()}
				/>
			</InputGroup>
		  </FormGroup>
		  </Col>
		  </Row>
		  <FormGroup>
			<label>Additional comments/questions?</label>
			<Input type="textarea" rows="6" maxLength="1000" value={content}
				onChange={e => setContent(e.target.value)}
				/>
		  </FormGroup>
		  <Row>
			<Col md="12">
			  <Button className="pull-right" color="primary" disabled={!valid} onClick={sendMessage}>Send Message</Button>
			</Col>
		  </Row>
		</Form>
		</>
	)
}
