import React from 'react';
import { Container } from 'reactstrap';

import Navbar from '../Navbar'
import Footer from '../Footer'

export default function FormPage(props) {
	return (
	  <>
	  <div className="header-2" filter-color="black">
	    <Navbar isLanding={false} />
	    <div className="features-2 section-image" style={{ backgroundImage: "url(/img/bg7.jpg)" }}>
          <div className="content">
		    <Container>
		      {props.children}
		    </Container>
          </div>
	    <Footer isLanding={false} />
	    </div>
      </div>
	  </>
	);
};
