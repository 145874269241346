import React from 'react';

import { InfoPage, InfoSection, InfoParagraph } from 'views/InfoPage';

export default function CancellationPolicy() {
	return (
		<>
		<InfoPage title="Cancellation and Refund Policy">
		  <InfoParagraph>
			Customers must agree to the following policies as a condition of shuttle service purchase.  Any decision
			and/or action taken by Peak Shuttle in accordance with these policies is final.
		  </InfoParagraph>
		  <InfoSection title="up to 48 hours">
			<InfoParagraph>
			  Customers may cancel shuttle reservations for a full refund up to 48 hours before scheduled departure.
			</InfoParagraph>
			<InfoParagraph>
			  Please email Customer Support at <a href="mailto:support@peak-shuttle.com">support@peak-shuttle.com</a> to cancel your
			  reservation
			</InfoParagraph>
		  </InfoSection>
		  <InfoSection title="within 48 hours prior to departure">
		    <InfoParagraph>
			  Customers wishing to cancel a shuttle reservation within 48 hours prior to scheduled departure are NOT
			  entitled to any refund.
			</InfoParagraph>
			<InfoParagraph>
			  Peak Shuttle may, at it's sole discretion, allow a customer to reschedule a reservation for shuttle
			  service departing within 48 hours.  Rescheduling is subject to availability and is not guaranteed.
			</InfoParagraph>
			<InfoParagraph>
			  Please email Customer Support at <a href="mailto:support@peak-shuttle.com">support@peak-shuttle.com</a> to cancel your
			  reservation
			</InfoParagraph>
		  </InfoSection>
		  <InfoSection title="inclement weather">
		    <InfoParagraph>
			  Inclement weather is not under the control of Peak Shuttle, and may result in significant service delays
			  and/or service cancellation.  Peak Shuttle will not operate vehicles in unsafe conditions.  If inclement
			  weather is expected, Peak Shuttle will contact customer (via phone, or email if phone not provided) and
			  may alter shuttle service departure times.  In some extreme cases, service may be cancelled, and customers
			  will receive a refund or can reschedule service.  Peak Shuttle assumes no liability for any cost
			  incurred by the customer due to inclement weather.
			</InfoParagraph>
		    <InfoParagraph>
			  It is the customer's responsibility to reserve the correct shuttle service, taking into account travel time,
			  airport checkin times, airport security lines, etc.  Peak Shuttle does not guarantee shuttle destination
			  arrival times.
			</InfoParagraph>
		    <InfoParagraph>
			  The safety of our customers is of utmost importance.  In all cases, Peak Shuttle drivers are required to
			  operate shuttle vehicles safely, and will not drive in an unsafe manner to meet customer flight departure
			  times.
			</InfoParagraph>
		  </InfoSection>
		  <InfoSection title="traffic delays">
		    <InfoParagraph>
			  Traffic delays can and do occur for a variety of reasons including inclement weather and accidents.  These
			  events are not under the control of Peak Shuttle.  In some instances, traffic delays may cause Peak Shuttle
			  customers to miss a scheduled flight.  Peak Shuttle assumes no liability for missed flights, flight rescheduling costs,
			  or any other cost incurred by the customer due to traffic delays.
			</InfoParagraph>
		    <InfoParagraph>
			  It is the customer's responsibility to reserve the correct shuttle service, taking into account travel time,
			  airport checkin times, airport security lines, etc.  Peak Shuttle does not guarantee shuttle destination
			  arrival times.
			</InfoParagraph>
		    <InfoParagraph>
			  The safety of our customers is of utmost importance.  In all cases, Peak Shuttle drivers are required to
			  operate shuttle vehicles safely, and will not drive in an unsafe manner to meet customer flight departure
			  times.
			</InfoParagraph>
		  </InfoSection>
		  <InfoSection title="flight delays">
			<InfoParagraph>
			  Flight delays are common and not under the control of Peak Shuttle.  We will make every attempt to accommodate 
			  travelers that cannot meet the shuttle driver by the scheduled departure time.  In some cases, flight delays can
			  result in significant delays to Peak Shuttle customers.
			</InfoParagraph>
			<InfoParagraph>
			  Peak Shuttle operates a shared transportation service (unless Private Shuttle service is purchased).  Often
			  several parties with different flight schedules will be traveling on the same shuttle trip.  In some instances,
			  shuttle departure time from the airport will be delayed, at the discretion of the driver, to accommodate
			  parties arriving on delayed flights.  Scheduled shuttle airport departure times and destination arrival times are
			  not guaranteed.
			</InfoParagraph>
		  </InfoSection>

		  <InfoSection title="No Shows">
		    <InfoParagraph>
			  Customers that do not meet the shuttle driver at the scheduled pickup time, as indicated by the reservation,
			  will be considered a "No Show", effectively canceling the reservation.  This policy governing this type of
			  cancellation is described above in the section titled "Within 48 Hours Prior to Departure".  No refunds will
			  be issued to "No Show" customers.
			</InfoParagraph>
			<InfoParagraph>
			  Peak Shuttle operates a shared transportation service (unless Private Shuttle service is purchased).  Drivers
			  will wait a maximum of 5 (five) minutes for customers to board the shuttle vehicle, after which the customer
			  will be considered a "No Show".
			</InfoParagraph>
		  </InfoSection>

		  <InfoSection title="rescheduling">
		    <InfoParagraph>
			  Please email Customer Support at <a href="mailto:support@peak-shuttle.com">support@peak-shuttle.com</a> to
			  reschedule shuttle service.
			</InfoParagraph>
		    <InfoParagraph>
			  Rescheduling is subject to availability and is not guaranteed.  If a reservation cannot be rescheduled, the
			  reservation can be canceled under the policies described above.
			</InfoParagraph>
		  </InfoSection>
		</InfoPage>
	    </>
	)
}
