import React from "react";

import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Row,
  Col,
} from "reactstrap";

import { Element } from 'react-scroll'

import SectionLink from './SectionLink';

const contentOne = [{
	id: 5,
	question: 'Where do you dropoff/pickup in Big Sky?',
	Answer: z => <div>We are a door-to-door service, and can dropoff/pickup at any address in the Big Sky area!  The online booking process will prompt you for your desired dropoff/pickup location</div>
}, {
	id: 3,
	question: 'Are your drivers vaccinated for the COVID-19 virus?',
	Answer: z => 'Yes.  Our drivers are fully vaccinated for the safety of our customers.  In addition, shuttle vehicles are sanitized after each trip.  If booking a Private Shuttle, you may request that the driver is wearing a face mask during the trip.  Unvaccinated customers are asked to please wear face masks.'
}, {
	id: 12,
	question: 'Can I book an entire vehicle/have a private shuttle?',
	Answer: z => 'Yes.  Peak Shuttle offers Private Shuttle service for customers wishing to reserve an entire vehicle.  Reservations can be made during the booking process.'
}, {
	id: 9,
	question: 'Do you dropoff/pickup at the Yellowstone Club?',
	Answer: z => 'Yes.  Homes at The Yellowstone Club is within our dropoff or pickup area'
}]

const contentTwo = [{
	id: 1,
	question: 'Will you transport skis, snowboards, or other sporting equipment?',
	Answer: z => <div>Yes.  Each traveler is allowed a single piece of sporting equipment (skis, snowboard, golf clubs, etc) and two pieces of luggage.  Exceptions will be allowed on a case by cases basis and can be requested by <SectionLink to="contact">Contacting Us</SectionLink></div>
}, {
	id: 10,
	question: 'Do you have child safety seats?',
	Answer: z => <div>Yes. <a target="_blank" rel="noreferrer" href="https://www.mdt.mt.gov/visionzero/docs/buckleup/childbrochure.pdf">Montana law</a> requires child safety restraints for passengers 6 years of age and under, or weighing less than 60 pounds.  We provide a variety of child safety seats including rear-facing, forward-facing, and booster seats for children under the age of 12.  You must provide age and weight information for child passengers during the booking process to reserve child seats</div>
}, {
	id: 2,
	question: 'Can I transfer my ticket to another person?',
	Answer: z => <div>Yes.  Please <SectionLink to="contact">Contact Us</SectionLink> if you need to transfer your ticket</div>
}, {
	id: 11,
	question: 'Are your vehicles wheelchair accessible?',
	Answer: z => 'At this time we cannot accommodate customers requiring wheelchair lifts'
}]

function Question(props) {
	let { content, isOpen, toggleCollapse } = props;

	return (
		<Card className="card-plain">
		  <CardHeader id="headingOne" role="tab">
		    <a aria-expanded={isOpen} data-toggle="collapse" href="#" onClick={toggleCollapse}>
			  { content.question }
			  <i className="now-ui-icons arrows-1_minimal-down"></i>
		    </a>
		  </CardHeader>
		  <Collapse isOpen={isOpen}>
		    <CardBody>
			  <content.Answer />
		    </CardBody>
		  </Collapse>
	    </Card>
	)
}

export default function FAQ() {
	const [collapses, setCollapses] = React.useState([]);
	const changeCollapse = (collapse) => {
		if (collapses.includes(collapse)) {
			setCollapses(collapses.filter(prop => prop !== collapse));
	  	}
	  	else setCollapses([...collapses, collapse]);
	};

	const toggleCollapse = (e, index) => {
		e.preventDefault();
		changeCollapse(index);
	}

	return (
		<div className="cd-section">
		  <Element name="faq" />
          <div className="features-1">
            <Container>
		      <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="title">Frequently Asked Questions</h2>
                  <h4 className="description">
                    Answers to commonly asked questions below.  Not finding what you're looking for?  Please <SectionLink to="contact">Contact Us</SectionLink> for a prompt response.
                  </h4>
                </Col>
              </Row>
		      <Row className="mt-5" style={{ textAlign: 'left' }}>
		        <Col md="6" className="px-5">
			      <div aria-multiselectable={true} className="card-collapse" id="accordion" role="tablist">
			        {
				      contentOne.map(c => {
					      return <Question key={c.id} isOpen={collapses.includes(c.id)} toggleCollapse={e => toggleCollapse(e, c.id)} content={c} />
			  	      })
			        }
			      </div>
		        </Col>
		        <Col md="6" className="px-5">
			      <div aria-multiselectable={true} className="card-collapse" id="accordion" role="tablist">
			        {
				      contentTwo.map(c => {
					      return <Question key={c.id} isOpen={collapses.includes(c.id)} toggleCollapse={e => toggleCollapse(e, c.id)} content={c} />
			  	      })
			        }
			      </div>
		        </Col>
		      </Row>
	        </Container>
	      </div>
	    </div>
	)
}