import React from "react";

import {
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
} from "reactstrap";

import ReactDatetime from "react-datetime";
import { Question } from 'book/steps/StepAlerts'
import { StepNextButton } from 'Widgets';
import moment from "moment-timezone";
import useLocalTime from "hook/useLocalTime";

// TODO: eventually get from service?
const AIRLINES = [{
	id: '1',
	name: 'Delta',
	image: undefined
}, {
	id: '2',
	name: 'United',
	image: undefined
}]

function AirlineSelect(props) {
	let { selectedAirline, onAirlineSelected } = props;

	return (
		<UncontrolledDropdown className="btn-group">
		  <DropdownToggle
				aria-expanded={false}
				aria-haspopup={true}
				caret
				color="info"
				data-toggle="dropdown"
				type="button"
				className="text-uppercase font-weight-bold"
		  		>
			{selectedAirline ? selectedAirline.name : ''}
		  </DropdownToggle>
		  <DropdownMenu>
			{
				AIRLINES.map(airline =>
					<DropdownItem key={airline.id} onClick={z => onAirlineSelected(airline)}>
					  {airline.name}
					</DropdownItem>
				)
			}
		  </DropdownMenu>
		</UncontrolledDropdown>
	);
}
  
export default function FlightStep(props) {
	let [airline, setAirline] = React.useState(AIRLINES[0]);
	let [flightTime, setFlightTime] = React.useState(moment());
	const [valid] = React.useState(true);

	let { booking } = props;

	let [localTime] = useLocalTime(Date.now(), booking.catalogItem.timeZone);

	React.useEffect(z => {
		let { travelDate, flight } = booking;

		if (flight) {
			setAirline(AIRLINES.find(a => flight.airline.id === a.id));
		};

		if (travelDate) {
			// console.debug(`init flight time (time=${travelDate.format('L LT')})`);

			setFlightTime(travelDate);
		}
	}, [booking]);

	/**
	 * NOTE: allow any flight time to be entered; trip availabilty will control whether or not a
	 * trip can be selected
	React.useEffect(z => {
		let diff = flightTime.diff(moment(), 'minutes');

		setValid(diff >= -60);
  	}, [flightTime]);
	 */

	/*
	function handleAirlineSelected(airline) {
		setAirline(airline);
	}
	*/

	function commit() {
		console.debug(`committed flight information (time=${flightTime.format('L')})`);

		let flight = {
			airline: 'default'
		}

		let updatedBooking = {
			travelDate: flightTime,
			flight
		}

		if (!booking.travelDate || !flightTime.isSame(booking.travelDate)) {
			updatedBooking.trip = undefined;  // clear out previous selected offer
			updatedBooking.reservation = undefined;  // clear out previous reservation
		}

		props.onComplete(updatedBooking);
	}

	// console.debug(`rendering Flight (time=${flightTime.format('L LT')})`);

	let questionText = booking.catalogItem.isAirportDestination ? 'About what time does your flight depart from BZN airport?' : 'About what time does your flight arrive at BZN airport?'
	let flightLabelText = booking.catalogItem.isAirportDestination ? 'flight departure time' : 'flight arrival time';

	return (
		<>
		<h5 className="text-right text-uppercase">Flight Information</h5>
		<h6 className="text-right text-uppercase">BZN time: { localTime.format('lll') }</h6>
		<hr />

		<div className="pt-2">
		  <Question>
			<strong>{ questionText }</strong>
			<div className="mt-2">
			  We will suggest the best shuttle trip for you based on your flight schedule
			</div>
		  </Question>
		</div>

		<div className="mt-5">
		  <div className="d-flex justify-content-center align-items-start">
		    <h6 className="text-uppercase mr-3">{ flightLabelText }</h6>
			<div style={{ color: "black" }}>
		      <ReactDatetime
			  	  input={false}
                  dateFormat={false}
                  value={flightTime}
				  timeConstraints={{ minutes: { step: 5 } }}
                  onChange={setFlightTime}
                />
			</div>
		  </div>
		</div>

		{/*
		  <Container>
		    <AirlineSelect selectedAirline={airline} onAirlineSelected={handleAirlineSelected} />
		  </Container>
		*/}

		<div className="d-flex justify-content-end">
		  <StepNextButton disabled={!valid} onClick={commit} />
		</div>
		</>
	)
}