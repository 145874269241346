import React from 'react';

import {
	Button,
	ButtonGroup, 
	Container,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Row, Col,
	Table,
	UncontrolledDropdown,
	UncontrolledTooltip
} from "reactstrap";

import Page from 'views/FormPage';

import LocationSearch from 'book/LocationSearch';
import LocationMap from 'book/LocationMap';

import ReactDatetime from "react-datetime";
import { Wrapper } from "@googlemaps/react-wrapper";
import { useHistory } from "react-router-dom";

import moment from 'moment-timezone';
import api from 'api.js';

const MAPS_KEY = process.env.REACT_APP_MAPS_KEY;

function SectionHeader(props) {
	return (
		<>
		  <h4>{props.title}</h4>
		  <hr className="section-divider" />
		</>
	)
}

function LocationSelector(props) {
	let { items, labelProperty, selectedItem, color } = props;

	return (
		<UncontrolledDropdown className="btn-group">
		  <DropdownToggle
				aria-expanded={false}
				aria-haspopup={true}
				caret
				color={color}
				data-toggle="dropdown"
				type="button"
				className="text-uppercase font-weight-bold"
		  		>
			{selectedItem[labelProperty]}
		  </DropdownToggle>
		  <DropdownMenu>
			{
				items.map(item =>
					<DropdownItem key={item.id} onClick={z => props.onItemSelected(item)}>
					  {item[labelProperty]}
					</DropdownItem>
				)
			}
		  </DropdownMenu>
		</UncontrolledDropdown>
	);
}

function PickupDateTime(props) {
	return (
		<div className="mt-3 d-flex">
		  <label className="text-uppercase">Pickup date/time</label>
		  <div style={{ color: "black" }}>
		    <ReactDatetime
			    input={false}
				{...props}
                //   timeFormat={false}
                //   isValidDate={current => current.isAfter(yesterday)}
              />
		  </div>
		</div>
	)
}

function FlightDateTime(props) {
	return (
		<>
		<SectionHeader title="Flight" />
	
		<div className="mt-3 d-flex">
		  <div style={{ color: "black" }}>
		  <FormGroup>
  			<ReactDatetime inputProps={{ placeholder: "Flight date/time" }} {...props} />
		  </FormGroup>
		  </div>
		</div>
		</>
	)
}

function Location(props) {
	let { catalogItem, place, onPlaceChanged } = props;

	/*
	let isPickup = catalogItem.arrivalLocation === 'BZN';

	let initialLocation = isPickup ? catalogItem.departureGeo : catalogItem.arrivalGeo;
	let searchBounds = isPickup ? catalogItem.departureBounds : catalogItem.arrivalBounds;
	*/

	let narrowLabel, initialLocation, searchBounds;
	if (catalogItem.narrowDeparture) {
		narrowLabel = 'departure';
		initialLocation = catalogItem.departureGeo;
		searchBounds = catalogItem.departureBounds;
	} else if (catalogItem.narrowArrival) {
		narrowLabel = 'arrival';
		initialLocation = catalogItem.arrivalGeo;
		searchBounds = catalogItem.arrivalBounds;
	} else return <div>fix catalog item</div>

	return (
		<div className="mt-2" style={{ textAlign: "left", backgroundColor: "white", color: "black" }}>
		  <label className="text-uppercase">Location</label><label className="text-muted"> - {narrowLabel}</label>

		  <LocationSearch place={place} searchBounds={searchBounds} onPlaceChanged={onPlaceChanged} />

		  <LocationMap location={initialLocation} place={place} markAddress={true} />
		</div>
	)
}

function Passengers(props) {
	let { catalogItem, passengerCount, passengerCountSelected } = props;

	let { maxPassengers } = catalogItem;
	let passengerOptions = [];
	for (let i = 0; i < maxPassengers; i++) passengerOptions.push(i+1);

	return (
		<>
		  <SectionHeader title="Passengers" />
		  <Container>
			<Row>
		      <Col lg="6">
				<UncontrolledDropdown className="btn-group">
		          <DropdownToggle
					  className="text-uppercase font-weight-bold"
					  color="primary"
					  caret
				      aria-expanded={false} aria-haspopup={true}
					  data-toggle="dropdown"
				      type="button"
		  		      >
			            {passengerCount}
		          </DropdownToggle>
		            <DropdownMenu>
					  {
					  	passengerOptions.map(c => <DropdownItem key={c} onClick={z => passengerCountSelected(c)}>{c}</DropdownItem>)
					  }
		            </DropdownMenu>
		        </UncontrolledDropdown>
		      </Col>
			  </Row>
		  </Container>
		</>
	)
}

function ProductTable(props) {
	let { catalogItem, lineItems, addLineItem, removeLineItem } = props;

	if (!catalogItem.products) return (
		<>
		  <SectionHeader title="Products" />

		  <p>
			NONE
		  </p>
	    </>
	)

	let productIds = Object.keys(catalogItem.products);

  	return (
    	<>
		  <SectionHeader title="Products" />

    	  <Table className="table-shopping m-3" responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th className="th-description">Name</th>
                <th className="text-right">Price</th>
                <th className="text-right">Qty</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
				{
					productIds.map(id => {
						let p = catalogItem.products[id];

						let tooltipId = `tt-${id}`;

						return (
							<tr key={id}>
							  <td>{ id }</td>
							  <td id={tooltipId}>{p.name}</td>
							  <td className="td-number">${p.price}</td>
							  <td className="td-number">{ lineItems[id] || 0 }</td>
							  <td className="td-actions">
								<ButtonGroup>
									<Button color="info" size="sm" onClick={z => addLineItem(id)}>
									<i className="now-ui-icons ui-1_simple-add"></i>
									</Button>
									<Button color="info" size="sm" onClick={z => removeLineItem(id)}>
									<i className="now-ui-icons ui-1_simple-delete"></i>
									</Button>
								</ButtonGroup>
								<UncontrolledTooltip delay={0} placement="right" target={tooltipId}>
									{p.description}
								</UncontrolledTooltip>
							  </td>
							</tr>
						)
					})
				}
        </tbody>
      </Table>
    </>
  );
}

function Notes(props) {
	return (
		<>
		  <SectionHeader title="Notes" />

		  <FormGroup>
		    <Input rows="10" type="textarea" {...props} />
	      </FormGroup>
	    </>
	)
}
  
export default function OneOffBooking(props) {
	const history = useHistory();

	const [catalogItems, setCatalogItems] = React.useState([]);
	const [catalogItem, setCatalogItem] = React.useState({});
	const [travelDate, setTravelDate] = React.useState(moment());
	const [flightTime, setFlightTime] = React.useState();
	const [place, setPlace] = React.useState();
	const [email, setEmail] = React.useState('');
	const [phoneNumber, setPhoneNumber] = React.useState('');
	const [passengerCount, setPassengerCount] = React.useState(1);
	const [lineItems, setLineItems] = React.useState({});
	const [notes, setNotes] = React.useState('');

	const [submitting, setSubmitting] = React.useState(false);
	const [currentField, setCurrentField] = React.useState();

	function changeCatalogItem(item) {
		setCatalogItem(item);

		// clear out existing data that might not be valid anymore
		setPlace();
	}

	function handleTravelDateChanged(newTravelDate) {
		setTravelDate(newTravelDate);
	}

	function handleFlightTimeChanged(newFlightTime) {
		setFlightTime(newFlightTime);
	}

	function emailChanged(e) {
		setEmail(e.target.value);
	}

	function phoneChanged(e) {
		setPhoneNumber(e.target.value);
	}

	function addLineItem(id) {
		let newLineItems = Object.assign({}, lineItems);

		newLineItems[id] = newLineItems[id] ? newLineItems[id] + 1 : 1;

		setLineItems(newLineItems);
	}

	function removeLineItem(id) {
		let newLineItems = Object.assign({}, lineItems);

		newLineItems[id] = newLineItems[id] ? newLineItems[id] - 1 : 0;

		setLineItems(newLineItems);
	}

	React.useEffect(z => {
		api.getCatalog()
			.then(items => {
				setCatalogItems(items);
				setCatalogItem(items && items.length > 0 ? items[0] : {});
			});
	}, []);

	function submit(transition) {
		let rezData = {
			pickupDateTime: travelDate.toDate().getTime(),
			location: place,
			email,
			phoneNumber,
			passengerCount,
			lineItems,
			notes
		}

		if (flightTime) rezData.flightTime = flightTime.toDate().getTime();

		setSubmitting(true);
		
		// console.debug('$$$$$$$$', rezData);

		return api.oneOff(catalogItem.id, rezData, transition)
			.then(rez => {
				setSubmitting(false);

				return rez;
			})
			;
	}

	function reserve() {
		return submit('RESERVE')
			.then(rez => history.push(`/rez/${rez.tripId}/${rez.id}`))
	}

	function hold() {
		return submit('HOLD')
			.then(rez => history.push(`/rez/${rez.tripId}/${rez.id}`))
	}
		
	function pay() {
		return submit('PAY')
			.then(rez => window.location = rez.paymentSession.url)
			;
	}

	return (
		<>
		  <Page>
		    <div className="d-flex flex-column reservation-form p-3">
			  <hr className="section-divider" />
		      <div className="d-flex">
		        <LocationSelector items={catalogItems} labelProperty="shortDescription" selectedItem={catalogItem}
				    onItemSelected={changeCatalogItem}
				    />
			  </div>
			
			  <Form style={{ textAlign: "left" }} onSubmit={e => e.preventDefault()}>
			    <PickupDateTime value={travelDate} onChange={handleTravelDateChanged} />

			    <FlightDateTime value={flightTime} onChange={handleFlightTimeChanged} />

				<Wrapper apiKey={MAPS_KEY} libraries={['places']}>
				  { catalogItem && <Location catalogItem={catalogItem} place={place} onPlaceChanged={place => setPlace(place)} /> }
	            </Wrapper>

          <FormGroup className="mt-3">
            <label className="text-uppercase">Email address</label>
            <InputGroup className={currentField === "email" ? "input-group-focus" : ""}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="now-ui-icons ui-1_email-85"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="email"
                value={email}
                autoComplete="email"
                placeholder="Email..."
                maxLength="100"
                onChange={emailChanged}
                onFocus={z => setCurrentField("email")}
                onBlur={z => setCurrentField()}
              />
            </InputGroup>
          </FormGroup>

		<hr />

          <FormGroup>
            <label className="text-uppercase reservation-form-label">Mobile Number</label>
            <InputGroup className={currentField === "phoneNumber" ? "input-group-focus" : ""}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="now-ui-icons tech_mobile"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="phoneNumber"
                value={phoneNumber}
                autoComplete="phoneNumber"
                placeholder="Mobile number..."
                maxLength="15"
                onChange={phoneChanged}
                onFocus={z => setCurrentField("phoneNumber")}
                onBlur={z => setCurrentField()}
              />
            </InputGroup>
          </FormGroup>

			    <Passengers
					catalogItem={catalogItem}
					passengerCount={passengerCount}
					passengerCountSelected={setPassengerCount}
					/>

				<ProductTable catalogItem={catalogItem} lineItems={lineItems} addLineItem={addLineItem} removeLineItem={removeLineItem} />

				<Notes value={notes} onChange={e => setNotes(e.target.value)} />
              </Form>


			  <div className="d-flex">
			    <Button color="info" disabled={submitting} onClick={reserve}>Reserve</Button>
			    <Button color="primary" disabled={submitting} onClick={hold}>Hold</Button>
			    <Button color="success" disabled={submitting} onClick={pay}>Pay</Button>
			  </div>
			</div>
		  </Page>
		</>
	)
}
