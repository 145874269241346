import React from 'react';

import ReservationDetails from 'support/ReservationDetails'
import { Warning } from 'book/steps/StepAlerts';

export default function BookConfirmation(props) {
	let message = (
		<Warning>
		  You have <strong>CANCELED</strong> payment for this reservation.  However, you can proceed with payment by clicking
		  the <strong>Pay Now</strong> button above
		</Warning>
	)

	return <ReservationDetails message={message} />
};