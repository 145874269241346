import React from 'react';

import {
	Collapse,
	NavbarBrand,
	Navbar,
	Container,
} from "reactstrap";

import Scroll from 'react-scroll';
import { useHistory } from 'react-router-dom';

import { LandingNav, NonLandingNav } from 'views/LandingNav';
  
export default function SiteNavbar(props) {
	const history = useHistory();
	const [collapseOpen, setCollapseOpen] = React.useState(false);

	function toggleCollapse(open) {
		document.documentElement.classList.toggle("nav-open", open);
		setCollapseOpen(open);
	}

	React.useEffect(z => {
		Scroll.Events.scrollEvent.register('begin', z => toggleCollapse(false));

		let unlisten = history.listen(({ action, location }) => toggleCollapse(false));
  
		return z => {
			Scroll.Events.scrollEvent.remove('begin');
			unlisten();
		}
	}, [history])

	return (
		<>
		{ collapseOpen && <div id="bodyClick" onClick={z => toggleCollapse(false)} /> }

		<Navbar className="navbar-transparent bg-info navbar-absolute" expand="lg">
		<Container>
		  <div className="navbar-translate">
			<button className="navbar-toggler" aria-expanded={collapseOpen} data-toggle="collapse" onClick={z => toggleCollapse(!collapseOpen)}>
			  <span className="navbar-toggler-bar bar1"></span>
			  <span className="navbar-toggler-bar bar2"></span>
			  <span className="navbar-toggler-bar bar3"></span>
			</button>
			<NavbarBrand href="https://www.peak-shuttle.com">Peak Shuttle</NavbarBrand>
		  </div>
		  <Collapse navbar isOpen={collapseOpen}>
			{ props.isLanding ? <LandingNav /> : <NonLandingNav /> }
		  </Collapse>
		</Container>
	  </Navbar>
	  </>
	)
}