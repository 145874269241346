import React from 'react';

import {
	Container,
	Row,
	Col
} from "reactstrap";
import { Element } from 'react-scroll';
import { useHistory } from 'react-router-dom';

import Loading from 'Loading';
import ServiceCard from './ServiceCard';

import api from 'api'

function BookNow(props) {
	const history = useHistory();
	const [catalogItems, setCatalogItems] = React.useState([]);

	React.useEffect(z => {
		api.getWebCatalog().then(setCatalogItems);
	}, [])

	let bookNow = (catalogItem) => {
		let state = {
			catalogItems,
			catalogItem
		};

		history.push('/book', state);
	};

	if (!catalogItems || catalogItems.length === 0) return (
		<Container>
		  <Element name="book" />
		  <div className="mt-5">
		    <Loading />
		  </div>
		</Container>
	)

	return (
		<Container>
		  <Element name="book" />
		  <Row>
			<Col className="ml-auto mr-auto text-center" md="8">
			  <img src="/logo/clear_trimmed.png" class="img-fluid" alt="Peak Shuttle"></img>
			  {/* <img src="/logo/dark_192.png" class="img-fluid" alt="Peak Shuttle"></img> */}
			  {/* <h2 className="title">Bozeman and Big Sky Shuttle</h2>
			  <h5 className="description">
				  Peak Shuttle provides safe and reliable transportation between Bozeman Yellowstone International Airport (BZN) and Big Sky Resort.  Planning your next
				  skiing or snowboarding adventure in Montana?  Book with us!
			  </h5>
			  <h3 className="fw-bold" style={{ color: 'white' }}>
				  Big Sky's ONLY door-to-door shuttle service to or from Bozeman Yellowstone International Airport (BZN)
			  </h3>
			*/}
			  <h3 className2="title" style={{ color: 'white' }}>
				Big Sky's ONLY door-to-door airport shuttle service!
			  </h3>
			</Col>
		  </Row>
		  <Row className="mt-3 justify-content-center">
			<Col md="4">
			  <ServiceCard catalogItem={catalogItems[0]} onFind={bookNow} />
			</Col>
			<Col md="4">
			  <ServiceCard catalogItem={catalogItems[1]} onFind={bookNow} />
			</Col>
		  </Row>
		</Container>
	)
}

export default BookNow;