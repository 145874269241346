import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: '876baf15-bfa0-4356-967c-88d88512fdba',
    clientToken: 'pub7392628104aad955751884cb58bbaece',
    site: 'datadoghq.com',
    service:'web',
    env:'prod',
    version: 'milestone-2',
    sampleRate: 100,
    trackInteractions: true
});

/*
datadogRum.addRumGlobalContext('dlr-session', sessionStorage.getItem('dc.session'));
datadogRum.addRumGlobalContext('dlr-player', sessionStorage.getItem('dc.player'));

datadogRum.addUserAction('initializing', {
	wssEndpoint: process.env.REACT_APP_WSS_ENDPOINT,
	imageEndpoint: process.env.REACT_APP_IMAGE_ENDPOINT
});
*/

class Monitor {

	error(name, info) {}

	event(name, context) {
		// no need to log errors (will still show in dev)
		datadogRum.addAction(name, context);
	}

}

export default Monitor;