const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

class Api {

	getCatalog() {
		return fetch(`${API_ENDPOINT}/catalog`)
			.then(r => r.json())
	}

	getWebCatalog() {
		return fetch(`${API_ENDPOINT}/catalog/bzn?channel=web`)
			.then(r => r.json())
	}

	searchTrips(searchParams) {
		let params = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(searchParams)
		}

		return fetch(`${API_ENDPOINT}/find`, params)
			.then(r => r.json())
	}

	reserve(offer, flightTime, existing) {
		let body = {
			tripId: offer.tripId,
			catalogItemId: offer.catalogId,
			scheduledDeparture: offer.scheduledDeparture,
			flightTime
		}

		if (existing) {
			body.existingTripId = existing.tripId;
			body.existingReservationId = existing.id;
		}

		let params = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(body)
		}

		return fetch(`${API_ENDPOINT}/reserve`, params)
			.then(r => r.json())
	}

	sendMessage(message) {
		let params = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(message)
		}

		return fetch(`${API_ENDPOINT}/contact`, params)
			.then(r => r.json())
	}

	paymentIntent(args) {
		let params = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(args || {})
		}

		return fetch(`${API_ENDPOINT}/stripe/intent`, params)
			.then(r => r.json())
	}

	paymentSession(args) {
		let params = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(args || {})
		}

		return fetch(`${API_ENDPOINT}/stripe/session`, params)
			.then(r => r.json())
	}

	updateBooking(booking) {
		booking.transition = 'UPDATE';

		let params = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(booking)
		}	

		return fetch(`${API_ENDPOINT}/booking`, params)
			.then(r => r.json())
	}

	confirmBooking(booking) {
		let params = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(booking)
		}

		return fetch(`${API_ENDPOINT}/booking`, params)
			.then(r => r.json())
	}

	getReservation(tripId, id) {
		let params = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		}

		return fetch(`${API_ENDPOINT}/reservation?tripId=${tripId}&id=${id}`, params)
			.then(r => {
				if (r.status === 200) return r.json();

				return Promise.reject({ statusCode: r.status });
			})
	}

	oneOff(catalogItemId, reservation, transition='RESERVE') {
		let params = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				catalogItemId,
				reservation,
				transition
			})
		}	

		return fetch(`${API_ENDPOINT}/oneoff`, params)
			.then(r => r.json())
	}

}

export default new Api();