import React from 'react';

import {
	Button
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faStepForward as nextIcon,
	faHourglassStart as committingIcon,
} from '@fortawesome/free-solid-svg-icons'

function StepNextButton(props) {
	let { committing } = props;

	if (committing) return (
	  <Button className="mt-5 text-uppercase font-weight-bold" size="lg" color="primary" disabled={true}>
		<FontAwesomeIcon icon={committingIcon} />
	  </Button>
	)

	return (
		<>
		  <Button className="mt-5 text-uppercase font-weight-bold " size="lg" color="primary" disabled={props.disabled} onClick={props.onClick}>
			<FontAwesomeIcon className="mr-3" icon={nextIcon} />
			Next
		  </Button>
		</>
	)
}

export {
	StepNextButton
}