import React from 'react';

import {
	Card,
	CardBody,
	Container,
	Form,
	FormGroup,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
} from "reactstrap";

import { Info } from 'book/steps/StepAlerts';
import LocationMap from 'book/LocationMap';
import { StepNextButton } from 'Widgets';

import api from 'api';

function SearchField(props) {
	const [hasFocus, setHasFocus] = React.useState(false);
	const inputEl = React.useRef(null);

	let { place, onPlaceChanged, searchBounds } = props;

	React.useEffect(z => {
		if (!inputEl.current || !place) return;

		inputEl.current.value = place.name;
	}, [place, inputEl]);

	React.useEffect(z => {
		// if (true) return;
		if (!inputEl.current) return;

		const options = {
			bounds: searchBounds,
			componentRestrictions: { country: "us" },
			fields: ["address_components", "geometry", "name"],
			strictBounds: true,
		};

		let ac = new window.google.maps.places.Autocomplete(inputEl.current, options);

		let listener = ac.addListener('place_changed', z => {
			let place = ac.getPlace();

			/**
			 * NOTE: If the user enters the name of a Place that was not suggested by the control
			 * and presses the Enter key, or if a Place Details request fails, the PlaceResult
			 * contains the user input in the name property, with no other properties defined
			 * 
			 * TODO: in this case, should show error message
			 */
			if (place.address_components) {
				/**
				 * NOTE: dont like how the widget puts the full place in the text field, but
				 * doesn't seem to be a way to customize this; attempted to overwrite with address,
				 * but its kind of klunky.  maybe just show address statically somewhere
				let formatted = place.address_components
					.slice(0, 3)
					.map(c => c.short_name)
					.join(', ');
				 */
	
				// setLocation(formatted);

				inputEl.current.value = place.name;

				onPlaceChanged(place);
			}
		});

		return z => listener.remove();
	}, [inputEl, onPlaceChanged, searchBounds]);

	return (
		<>
		  <FormGroup>
		    <label className="text-uppercase">Address or Place</label>
			<InputGroup className={ hasFocus ? "input-group-focus" : "" }>
			  <InputGroupAddon addonType="prepend">
				<InputGroupText>
				  <i className="now-ui-icons location_pin"></i>
				</InputGroupText>
			  </InputGroupAddon>
			  <Input type="text" innerRef={inputEl} placeholder="Location near Big Sky"
				  onFocus={z => setHasFocus(true)}
				  onBlur={z => setHasFocus(false)}
				  />
			</InputGroup>
	      </FormGroup>
		</>
	)
}

export default function Location(props) {
	let { booking } = props;

	const [place, setPlace] = React.useState(booking.location);
	const [valid, setValid] = React.useState(false);
	const [committing, setCommitting] = React.useState(false);

	React.useEffect(z => {
		if (booking.location) {
			setPlace(booking.location);
		}

		setValid(booking.location);
	}, [booking]);

	function placeChanged(place) {
		setPlace(place);
		setValid(true);
	}

	function commit() {
		console.debug(`committed location information`);

		setCommitting(true);

		let params = {
			reservationId: booking.reservation.id,
			tripId: booking.reservation.tripId,
			location: place
		}

		return api.updateBooking(params)
			.then(reservation => {
				setCommitting(false);

				props.onComplete({
					location: place,
					reservation
				});
			})
			;
	}

	let isPickup = booking.catalogItem.arrivalLocation === 'BZN';
	let pageTitle = isPickup ? 'Pickup Location' : 'Dropoff Location';
	let initialLocation = isPickup ? booking.catalogItem.departureGeo : booking.catalogItem.arrivalGeo;
	let searchBounds = isPickup ? booking.catalogItem.departureBounds : booking.catalogItem.arrivalBounds;

	return (
		<>
		  <h5 className="text-right text-uppercase">{pageTitle}</h5>
		  <hr />

		  <Info>
		    Peak Shuttle services any address within 10 miles of Big Sky
		  </Info>

		  <Container>
		    <Card className="mt-2 card-raised card-form-horizontal card-pricing" style={{ textAlign: "left", backgroundColor: "white", color: "black" }}>
			  <CardBody>
				  <Form className="mb-3" onSubmit={e => e.preventDefault()}>
					<SearchField place={place} searchBounds={searchBounds} onPlaceChanged={placeChanged}/>
				  </Form>
				  <LocationMap location={initialLocation} place={place} markAddress={true} />
				</CardBody>
			</Card>
		  </Container>

		  <div className="d-flex justify-content-end">
			<StepNextButton disabled={!valid} onClick={commit} committing={committing} />
	      </div>
		</>
	)
}