import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

import Landing from './Landing';
import Book from './book/Book';
import OfferDetails from './book/OfferDetails';
import BookConfirmation from './confirm/BookConfirmation';
import BookCancel from './confirm/BookCancel';
import ContactConfirmation from './confirm/ContactConfirmation';
import ReservationDetails from 'support/ReservationDetails';
import OneOffBooking from 'book/OneOffBooking';
import Meet from 'support/Meet';

import TermsOfUse from 'landing/TermsOfUse';
import PrivacyPolicy from 'landing/PrivacyPolicy';
import CancellationPolicy from 'landing/Cancellation';

import Monitor from 'monitor';
import Support from 'Support';

import "assets/css/now-ui-kit.min.css";
import "assets/css/ps.css";

class App extends React.Component {

	static getDerivedStateFromError(error) {
		return {
			hasError: true,
			error
		}
	}

	monitor = new Monitor()

	state = {
		hasError: false
	}

	componentDidCatch(error, info) {
		this.monitor.event('REACT_ERROR', { boundary: 'APP' });
	}

	render() {
		let { hasError, error } = this.state;

		if (hasError) return <Support error={ error } />

		return (
			<BrowserRouter>
			  <Switch>
			    <Route path="/bozeman" render={props => <Landing {...props} />} />
		
			    <Route path="/book" render={props => <Book {...props} />} />
			    <Route path="/offer" render={props => <OfferDetails {...props} />} />
			    <Route path="/bookConfirm/:tripId/:id" render={props => <BookConfirmation {...props} />} />
			    <Route path="/cancel/:tripId/:id" render={props => <BookCancel {...props} />} />
		
			    <Route path="/terms" render={props => <TermsOfUse {...props} />} />
			    <Route path="/privacy" render={props => <PrivacyPolicy {...props} />} />
			    <Route path="/cancellation" render={props => <CancellationPolicy {...props} />} />

			    <Route path="/confirm/contact" render={props => <ContactConfirmation {...props} />} />
		
			    <Route path="/rez/:tripId/:id" render={props => <ReservationDetails {...props} />} />

			    <Route path="/oneoff" render={props => <OneOffBooking {...props} />} />

			    <Route path="/meet" render={props => <Meet {...props} />} />
		
			    <Redirect to="/bozeman" />
			  </Switch>
		    </BrowserRouter>
		)
	}

}

ReactDOM.render(
	<App />,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
