import React from "react";

const MAP_STYLES = {
	height: '50vh',
}

export default function LocationMap(props) {
	let [initialized, setInitialized] = React.useState(false);
	let [map, setMap] = React.useState();
	let [marker, setMarker] = React.useState();
	let [addressWindow, setAddressWindow] = React.useState();

	let { location, place, markAddress } = props;

	React.useEffect(z => {
		if (initialized) return;

		let center = place && place.geometry ? place.geometry.location : location;

		let map = new window.google.maps.Map(document.getElementById("locationMap"), {
			center,
			zoom: 12,
			fullscreenControl: false,
			streetViewControl: false,
			mapTypeControlOptions: {
				mapTypeIds: ['ROADMAP']
			}
		});

		setMap(map);
		setMarker(new window.google.maps.Marker({ position: center }));
		setAddressWindow(new window.google.maps.InfoWindow());
		setInitialized(true);
	}, [initialized, location, place]);

	React.useEffect(z => {
		if (!markAddress || !initialized || !place || !place.geometry) return;

		if (marker) marker.setMap(null);
		if (addressWindow) addressWindow.close();

		map.setZoom(15);
		map.panTo(place.geometry.location);

		marker.setPosition(place.geometry.location);
		marker.setMap(map);
		marker.setAnimation(window.google.maps.Animation.DROP);

		/**
		 * NOTE: was trying to use an address here first; just use the location name
		 */
		// let formatted = `${place.address_components[0].short_name} ${place.address_components[1].short_name}, ${place.address_components[2].short_name}`;
		// addressWindow.setContent(formatted);

		addressWindow.setContent(place.name);

		addressWindow.open({ anchor: marker, map, shouldFocus: false });
	}, [place, initialized, map, marker, markAddress, addressWindow]);

	return (
		<>
		  <div id="locationMap" style={MAP_STYLES}></div>
		</>
	)
}
