import React from "react";

// reactstrap components
import {
  Badge,
  Card,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faPlane as arrivalIcon,
	faClock as durationIcon,
	faPeopleArrows as meetIcon,
	faShuttleVan as driveIcon,
	faMapMarkedAlt as dropoffIcon,
} from '@fortawesome/free-solid-svg-icons'

import LocationMap from 'book/LocationMap'

import moment from "moment-timezone";

const ICONS = {
	arrival: arrivalIcon,
	pickup: meetIcon,
	drive: driveIcon,
	dropoff: dropoffIcon
}

function TimelineEvent(props) {
	let { event, timeZone } = props;
	let { actions } = event;

	/**
	 * NOTE: want to show start and end times for pickup/dropoff to account for multiple addresses;
	 * this is a cheap way to do this; however this may be best setup on the server side
	 */
	let showEnd = event.place !== undefined;
	let timeLabel = moment(event.start.ts).tz(timeZone).format('LT');
	if (showEnd) timeLabel += ` - ${moment(event.end.ts).tz(timeZone).format('LT')}`;

	let duration = moment.duration(event.end.ts - event.start.ts, 'ms');

	let icon = ICONS[event.type] || arrivalIcon;

	return (
		<>
		  <div className="timeline-badge success">
		    <FontAwesomeIcon icon={icon} />
	      </div>
	      <div className="timeline-panel text-left">
		    <div className="timeline-heading">
		      <div className="d-flex justify-content-between align-items-baseline">
		        <h3 className="mt-1 mb-1">{timeLabel}</h3>
		        <h5 className="mt-1 mb-1"><Badge color="info">{event.type}</Badge></h5>
		      </div>
		    </div>
		    <div className="timeline-body">
			  { props.children }
		    </div>
		    <hr className="mb-1" />
		    <div className="timeline-footer">
			  <div className="d-flex justify-content-between align-items-end">
		        <h6>
		          <FontAwesomeIcon icon={durationIcon} />
		          <span className="pl-2">{duration.humanize()}</span>
		        </h6>
				{
					actions && 
			    <UncontrolledDropdown>
				  <DropdownToggle caret className="btn-round" color="secondary" data-toggle="dropdown" type="button">
					<i className="now-ui-icons design_bullet-list-67" />
				  </DropdownToggle>
				  <DropdownMenu>
					<DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
						Action
					</DropdownItem>
					<DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
						Action
					  </DropdownItem>
					</DropdownMenu>
				  </UncontrolledDropdown>
				}
				</div>
		    </div>
	      </div>
	    </>
	)
}

function TextContent(props) {
	let { event } = props;

	return (
		<>
		  <h5>{event.title}</h5>

		  <p className="mb-3">{event.content}</p>
		</>
	)
}

function MapContent(props) {
	let { event } = props;
	
	return (
		<>
		  <h5 className="mb-3">{event.title}</h5>

		  <p className="mb-3">{event.content}</p>

		  <LocationMap place={event.place} markAddress={true} />
		</>
	)
}

/*
const CONTENT_MAP = {
	arrival: TextContent,
	pickup: TextContent,
	drive: TextContent,
	dropoff: MapContent
}
*/

/**
 * Displays the timeline of a Reservation
 * 
 * WIP
 * 	- 
 * 
 * @param {*} props 
 * @returns 
 */
export default function Timeline(props) {
	let { reservation } = props;
	let { events } = reservation;

	return (
		<Card className="card-timeline card-plain">
		  <CardBody>
		  <ul className="timeline timeline-simple">
		{
			events.map(e => {
				// let Content = CONTENT_MAP[e.type] || TextContent;

				let Content = e.place ? MapContent : TextContent;

				return (
					<li className="timeline-inverted" key={e.type}>
					  <TimelineEvent icon={arrivalIcon} event={e} timeZone={reservation.timeZone}>
					    <Content event={e} />
					  </TimelineEvent>
				    </li>
				)
			})
		}
		</ul>
		</CardBody>
		</Card>
	)
}
