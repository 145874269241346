import React from 'react';

import {
	Container
} from "reactstrap";

import Navbar from '../Navbar';
import Footer from '../Footer';

function InfoParagraph(props) {
	return (
		<>
		<div className="mt-1 mb-2 text-left">
		  {props.children}
		</div>
	    </>
	)
}

function InfoSection(props) {
	let { title } = props;

	return (
		<>
		  <h5 className="mt-3 text-right text-uppercase">{title}</h5>

		  <hr className="section-divider" />

		  <div className="p-3 mb-3 text-left">
		    {props.children}
		  </div>
		</>
	)
}

function InfoPage(props) {
	let { title } = props;

	return (
	  <>
	  <div className="header-2" filter-color="black">
	    <Navbar isLanding={false} />
	    <div className="features-2 section-image" style={{ backgroundImage: "url(/img/bg7.jpg)" }}>
          <div className="content" style={{ color: 'white' }}>
		    <Container>
			  <h4 className="text-right text-uppercase" style={{ color: 'white' }}>{title}</h4>
		      <hr className="section-divider" />

		      {props.children}
		    </Container>
          </div>
	      <Footer isLanding={false} />
	    </div>
      </div>
	  </>
	);
};

export {
	InfoPage,
	InfoSection,
	InfoParagraph
}