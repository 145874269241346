import React from "react";

import {
  Card,
  CardBody,
  Container,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

import { Info } from 'book/steps/StepAlerts';
import { StepNextButton } from 'Widgets';

import api from 'api';

const EMAIL_REGEX = /^\S+@\S+$/;
const PHONE_REGEX = /^[0-9\-()\s]+$/;

export default function ContactInformation(props) {
	const [currentField, setCurrentField] = React.useState();
	const [valid, setValid] = React.useState(false);
	const [email, setEmail] = React.useState('');
	const [phoneNumber, setPhoneNumber] = React.useState('');
	const [committing, setCommitting] = React.useState(false);

	let { booking } = props;

	React.useEffect(z => {
		if (booking.contact) setEmail(booking.contact.email);
	}, [booking]);

	React.useEffect(z => {
		setValid(true
			&& email
			&& email.length > 0
			&& EMAIL_REGEX.test(email)
			&& PHONE_REGEX.test(phoneNumber)
			);
	}, [email, phoneNumber]);

	function emailChanged(e) {
		setEmail(e.target.value);
	}

	function phoneChanged(e) {
		setPhoneNumber(e.target.value);
	}

	function commit() {
		let contact = {
			email,
			phoneNumber
		}

		console.debug(`committed contact information`);

		setCommitting(true);

		let params = {
			reservationId: booking.reservation.id,
			tripId: booking.reservation.tripId,
			contact
		}

		return api.updateBooking(params)
			.then(reservation => {
				setCommitting(false);

				props.onComplete({
					contact,
					reservation
				});
			})
			;
	}

	return (
    <>
      <h5 className="text-right text-uppercase">Contact Information</h5>
      <hr />

	  <Info>Please provide a mobile phone number so that we may quickly contact you on the day of your trip</Info>

	  <Container>
      <Card className="mt-3 card-raised card-form-horizontal card-pricing" style={{ textAlign: "left", backgroundColor: "white", color: "black" }}>
        <CardBody>
        <Form>
          <FormGroup>
            <label className="text-uppercase">Email address</label>
            <InputGroup className={currentField === "email" ? "input-group-focus" : ""}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="now-ui-icons ui-1_email-85"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="email"
                value={email}
                autoComplete="email"
                placeholder="Email..."
                maxLength="100"
                onChange={emailChanged}
                onFocus={(z) => setCurrentField("email")}
                onBlur={(z) => setCurrentField()}
              />
            </InputGroup>
          </FormGroup>
          <div className="p-3 text-muted">
		    Your email address is required to send confirmation of this booking
		  </div>
          <FormGroup>
            <label className="text-uppercase">Mobile Number</label>
            <InputGroup className={currentField === "phoneNumber" ? "input-group-focus" : ""}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="now-ui-icons tech_mobile"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="phoneNumber"
                value={phoneNumber}
                autoComplete="phoneNumber"
                placeholder="Mobile number..."
                maxLength="15"
                onChange={phoneChanged}
                onFocus={(z) => setCurrentField("phoneNumber")}
                onBlur={(z) => setCurrentField()}
              />
            </InputGroup>
          </FormGroup>
          <div className="p-3 text-muted">
		    We will send you text messages related to your trip including traffic and weather delays.  Standard Text Rates may apply
		  </div>
        </Form>
		</CardBody>
		</Card>
      </Container>

	  <div className="d-flex justify-content-end">
		<StepNextButton disabled={!valid} onClick={commit} committing={committing} />
	  </div>
    </>
  );
}