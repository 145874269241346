import React from 'react';

const styles = {
	container: {
		width: '100vw',
		height: '100vh',

		// backgroundColor: 'red'
	}
}
  
export default function Meet() {
	return (
		<>
		  <div className="d-flex flex-column justify-content-center align-items-center" style={styles.container}>
		    <img className="img-fluid flex-grow-1" alt="Peak Shuttle" src="/logo/clear_trimmed_2.png" />

			<h1 className="display-1 fw-bolder">to Big Sky</h1>

			<h2 className="display-5 text-muted">Seats Available</h2>
		  </div>
		</>
	)
}