import {
	NavItem,
	NavLink,
} from "reactstrap";
  
import { ScrollLink } from 'react-scroll';

const ScrollableLink = ScrollLink(function(props) {
	return (
		<NavLink {...props} href="#">
		  { props.children }
        </NavLink>
	)
})

function SectionNav(props) {
	return (
		<NavItem>
		  <ScrollableLink {...props} smooth={true} spy={true} duration={500}>
		    { props.children }
		  </ScrollableLink>
	    </NavItem>
	)
}

export default SectionNav;